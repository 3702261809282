import { ErrorProdutosCampanhaStyled } from "./style"
import Atencao from "../../../img/atencao.svg"
import { ReactSVG } from "react-svg"

export const ErrorProdutosCampanha = ({erros, setErros}) => {
    return (
        <ErrorProdutosCampanhaStyled>
            <div className="div-branca">
                <div className="header">
                    <ReactSVG src={Atencao}/>
                    <h2>
                        Revise as informações
                    </h2>
                </div>
                <div className="scroll">
                <div className="lista">
                    {
                        erros.map((item) => 
                            <div className="card">
                            <h3>{item.codigo} | 
                                <h4>
                                {item.produto}
                                </h4>
                            </h3>
                            {item.erros.map((erro) => {
                                return(
                                    <h5>{erro}</h5>
                                )
                            })}
                        </div>
                    )}
                </div>
                </div>
                <span className="close" onClick={() => setErros()}>X</span>
            </div>
        </ErrorProdutosCampanhaStyled>
    )
}