import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100vh;
    background-color: rgb(239, 239, 236);

    .body {
        padding: 20px;
    }

    .body > div {
        background-color: #ffffff;
        overflow: auto;
        height: 90vh;
        border-radius: 0px 0px 9px 9px;
    }

    h1{
        background-color: rgb(220, 68, 5);
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        color: rgb(255, 255, 255);
        padding: 10px;
        display: flex;
        justify-content: space-between;

        span{
            border-radius: 100px;
            border: 3px solid #ffffff;
            padding: 0px 8px;
            cursor: pointer;
        }
    }

    .space-between{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;

        button{
            border:none;
            border-radius: 6px;
            padding: 8px 10px;
            font-size: 16px;
            font-weight: 600;
            background: #ff0000;
            color: white;
        }
    }

    h2{
        margin-top: 10px;
        color: rgb(220, 68, 5);
        font-size: 30px;
        margin: 10px auto 0px;
        width: 100%;
    }

    .data{
        font-size: 16px;
        font-weight: 600;
        margin: 10px auto 0px;
        width: 100%;
    }

    @media(min-width: 700px){
    .body {
        margin-left: 220px;
    }
  }
`

export const TableContainer = styled.div`
    overflow: auto;
    padding-bottom: 30px;
    width: 90%;
    margin: 30px auto 0px;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: #dfdddd;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(220, 68, 5);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;
    table-layout: unset;

    > thead{
        position: unset;
    }

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      border-bottom: 1px solid var(--grayDA);
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
      display: revert;
      align-items: center !important;
    }

    @media(max-width: 600px){
        tr {
            display: table-row;
        } 

        td {
            text-align: center;
        }
    }
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: var(--azul);
    font-size: 0.8rem;
  }

  .validacao{
    width: 25px;
  }
`;

