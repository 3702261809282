import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import { createContext } from 'react';

export const CampanhaContext = createContext({});

export default function CampanhaProvider({children}){
    const schema = yup.object().shape({
        nome: yup.string().trim().required('Campo obrigatório.'),
        formatosId: yup.array().required('Campo obrigatório.'),
        dataInicio: yup.date().typeError('Campo obrigatório.').required('Campo obrigatório.'),
        dataFim: yup.date().typeError('Campo obrigatório.').required('Campo obrigatório.'),
    })

    const { register, handleSubmit, setValue, reset, setError, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    return(
        <CampanhaContext.Provider value={{ register, handleSubmit, reset, setValue, setError, errors }}>
            {children}
        </CampanhaContext.Provider>
    )
}