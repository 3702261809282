import { SpanStatus, TD, TR } from "./style";
import { MdOutlineAttachMoney } from "react-icons/md";
import { IoTrashOutline } from 'react-icons/io5';
import { useState } from "react";
import CampanhaProdutoPrecos from "../../modals/CampanhaProdutoPrecos";
import { segmentos } from "../../../utils/tiposDeSegmentoDeLoja";
import { ReactSVG } from "react-svg";
import Check from "../../../img/check.svg"
import Nocheck from "../../../img/nocheck.svg"

export const CampanhaProdutoTr = ({ produto, removerProduto, getProdutoPrecos, atualizarProdutoPrecos }) => {
    const [open, setOpen] = useState(false);
    const [abrirDetalhes, setAbrirDetalhes] = useState(false)

    return (
        <TR key={produto.id}>
            <TD data-label='Id'>{produto.id}</TD>
            <TD data-label='Código'>{produto.codigo}</TD>
            <TD data-label='Nome'>{produto.produto}</TD>
            <TD data-label='Categoria'>{produto.categoria}</TD>
            <TD data-label='Titulo'>{produto.descricao}</TD>
            <TD data-label='Subtítulo'>{produto.descricao2}</TD>
            <TD data-label='Subtítulo' className="Validacao">
                {(produto.descricao.length > 27 || produto.descricao2.length > 30) 
                    ? 
                <ReactSVG src={Nocheck} onMouseEnter={() => setAbrirDetalhes(true)} onMouseLeave={() => setAbrirDetalhes(false)}/>  
                    : 
                <ReactSVG src={Check}/>}
                {abrirDetalhes && 
                    <div className="detalhes">
                        Limite de caracteres excedido! Seu layout pode ser comprometido nos seus formatos:
                        <ul>
                            {(produto.descricao.length > 27 || produto.descricao2 > 30) && <li> - Cavalete Swift em dobro</li>}
                            {(produto.descricao.length > 36 || produto.descricao2 > 36) && 
                            <>
                                <li> - Etiqueta Swift em dobro</li>
                                <li> - A4 Swift em dobro</li>
                            </>
                            }
                        </ul>
                    </div>}
            </TD>
            <TD data-label='Frase Promocional'>{produto.frase_promocional}</TD>
            <TD data-label='Unidade Promocional'>{produto.unidade_de_medida_promocional}</TD>
            <TD data-label='Segmento da loja'>{segmentos.find(item => item.id === produto.tipoLoja_id).nome}</TD>
            <TD data-label='Status'>
                <SpanStatus
                    style={{
                        backgroundColor: produto.status === 'ATIVO' ? '#29C013' : '#FF0000'
                    }}
                >{produto.status}</SpanStatus>
            </TD>
            <TD data-label='Preços' className='preco' >
                <button
                    type='button'
                    onClick={() => setOpen(true)}
                >
                    <MdOutlineAttachMoney
                        size={30}
                        color='#29C013'
                        title="Precificar Produto"
                    />
                </button>

                {(open) && (
                    <CampanhaProdutoPrecos
                        fechar={() => setOpen(false)}
                        getProdutoPrecos={getProdutoPrecos}
                        atualizarProdutoPrecos={atualizarProdutoPrecos}
                    />
                )}
            </TD>
            <TD data-label='#'>
                <button
                    type='button'
                    onClick={() => removerProduto(produto.id)}
                >
                    <IoTrashOutline size={30} color='#FF0000' />
                </button>
            </TD>
        </TR>
    )
};