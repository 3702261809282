import * as XLSX from "xlsx/xlsx.mjs";

export const baixarPlanilha = ({
    nomeDoArquivo = 'exemplo.xlsx',
    abas = [{
        nome: 'exemplo',
        dados: [{}],
    }],
}) => {
    const wb = XLSX.utils.book_new();
    
    abas.forEach(aba => {
        const ws = XLSX.utils.json_to_sheet(aba.dados);
        XLSX.utils.book_append_sheet(wb, ws, aba.nome);
    });
    
    XLSX.writeFile(wb, nomeDoArquivo);
}