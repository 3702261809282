import './style.css';
import api from '../../../../services/api';
import { ReactComponent as Campanha } from '../../../../img/campanha.svg';
import { useState ,useContext, useEffect} from "react";
import { AuthContext } from "../../../../contexts/auth";
import { toast } from "react-toastify";
import { CampanhaContext } from "../../../../contexts/campanha";
import Multiselect from 'multiselect-react-dropdown';
import { dataParaTexto } from "../../../../utils/date/dataParaTexto";
import { useParams, Link } from 'react-router-dom';
import VincularCampanhasProdutos from '../../../modals/VincularCampanhasProdutos';
import CampanhaProdutos from '../../../modals/CampanhaProdutos';

export default function FormularioCampanha(){
    const { campanhaId } = useParams();
    const [formatosSelecionados,setFormatosSelecionados] = useState([]);
    const [vincularProdutos,setVincularProdutos] = useState(false);
    const [visualizarProdutos,setVisualizarProdutos] = useState(false);
    const [idCampanha,setIdCampanha] = useState(campanhaId);

    const {register,handleSubmit,setValue,reset,setError,errors} = useContext(CampanhaContext);
    const {user,signOut} = useContext(AuthContext);
    const dataLoja = localStorage.getItem('dataLoja')
    const converterLoja = JSON.parse(dataLoja)

    const formatos = [
        { name: 'A4 Swift em dobro', id: 6  },
        { name: 'Etiqueta Swift em dobro', id: 7  },
        { name: 'Cavalete Swift em dobro', id: 8  }
    ]
     
    function criar(data){
        const dadosProduto = {
            nome: data.nome,
            formatosId: formatosSelecionados.map((item) => item.id),
            dataInicio: dataParaTexto(data.dataInicio),
            dataFim: dataParaTexto(data.dataFim),
            usuarioId: user.id_usuario
        }

        api.post('/campanhas',
            dadosProduto,
            {
                headers: { 'Authorization': user.token}
            }
        ).then((resp) => {
            setIdCampanha(resp.data.response.campanhaCriada.campanhaId);
            toast.success('Campanha criada com sucesso')
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
            }
        })
    }

    function editar(data){
        const dadosProduto = {
            nome: data.nome,
            formatosId: formatosSelecionados.map((item) => item.id),
            dataInicio: dataParaTexto(data.dataInicio),
            dataFim: dataParaTexto(data.dataFim),
            usuarioId: user.id_usuario
        }

        api.patch(`/campanhas/${idCampanha}`,
            dadosProduto,
            {
                headers: { 'Authorization': user.token}
            }
        ).then((resp) => {
            buscarCampanha();
            toast.success('Campanha editada com sucesso');
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
            }
        })
    }

    function submit(data){
        if(idCampanha){
            editar(data)
        }else{
            criar(data)
        }
    }

    function buscarCampanha(){
        api.get(`/campanhas/${idCampanha}`,
            {
                headers: { 'Authorization': user.token}
            }
        ).then((resp) => {
            const campanha = resp.data.campanha 

            const formatosIds = campanha?.formatosId?.length > 0 ? campanha.formatosId.split(',').map(formato => formatos.find(item => item.id === Number(formato))) : []

            setValue('nome', campanha.nome)
            setValue('dataInicio', campanha.dataInicio.split('/').reverse().join('-'))
            setValue('dataFim', campanha.dataFim.split('/').reverse().join('-'))

            setFormatosSelecionados(formatosIds)
            setValue('formatosId',formatosIds)
        }).catch((error)=>{
            if(error.response?.status === 401){
                toast.info(error.response?.data?.error)
                signOut()
            }else{
                toast.error(error.response?.data?.error)
            }
        })
    }

    useEffect(() => {
        if (!!idCampanha) buscarCampanha();

        if (!idCampanha) {
            reset({
                nome: '',
                dataInicio: '',
                dataFim: '',
                formatosId: []
            })
        }
    }, [idCampanha])
    
    return(
        <div className="solicitar-interno">
        <div className="solicitar-areacomponentes">
            <div className="solicitar-header">
                <Campanha size={34} className='icone' />

                {idCampanha ? <h3>Editar Campanha</h3> : <h3>Criar Campanha</h3>}
            </div>
            <div className="pedido-cadastro">
            <div className="form-container">
            <form className="solicitar-pedidos-form" onSubmit={handleSubmit(submit)}>
                <div className="input-container">
                    <h3>Nome *</h3>
                    <input
                        id='selProd'
                        {...register("nome")}
                        placeholder="Nome da campanha"
                    />
                    {errors.nome && <p style={{ color:"red"}}>{errors.nome.message}</p>}
                </div>
                <div className="campanha_input-container">
                    <h3>Formatos *</h3>
                    <Multiselect 
                        id='selForm'
                        options={formatos}
                        selectedValues={formatosSelecionados}
                        onSelect={(_, selectedItem) => setFormatosSelecionados((e) => {
                            const novoValor = [...e, selectedItem]
                            setValue('formatosId', novoValor.map((item) => item.id))
                            setError('formatosId', null)
                            return novoValor
                        })}
                        onRemove={(_, removedItem) => setFormatosSelecionados((e) => {
                            const novoValor = e.filter((item) => item.id !== removedItem.id)
                            setValue('formatosId', novoValor.map((item) => item.id))
                            return novoValor
                        })}
                        displayValue="name"
                        placeholder="Selecione os formatos"
                    />
                    {errors.formatosId && <p style={{ color:"red"}}>{errors.formatosId.message}</p>}
                </div>
                <div className="input-container">
                    <h3>Data de inicio *</h3>
                    <input
                        id='dataInicio'
                        type="date"
                        {...register("dataInicio")}
                    />
                    {errors.dataInicio && <p style={{ color:"red"}}>{errors.dataInicio.message}</p>}
                </div>
                <div className="input-container">
                    <h3>Data de fim *</h3>
                    <input
                        id='dataFim'
                        type="date"
                        {...register("dataFim")}
                    />
                    {errors.dataFim && <p style={{ color:"red"}}>{errors.dataFim.message}</p>}
                </div>
                <div className="button-container">
                    <Link
                        className="actionCancelar"
                        to="/admin/campanhas"
                    >
                        VOLTAR
                    </Link>

                    <button className="actionSolicitar" type="submit">
                        {idCampanha ? 'SALVAR' : "CRIAR"}
                    </button>

                    {(!!idCampanha) && (
                        <button
                            type="button"
                            className="actionSolicitar"
                            onClick={()=> setVisualizarProdutos(true)}
                        >
                            PRODUTOS
                        </button>
                    )}

                </div>
            </form>
            {(!!idCampanha && vincularProdutos) && (
                <VincularCampanhasProdutos
                    fechar={() => setVincularProdutos(false)}
                    voltar={() => {
                        setVincularProdutos(false)
                        setVisualizarProdutos(true)
                    }}
                    id={idCampanha}
                />
            )}

            {(!!idCampanha && visualizarProdutos) && (
                <CampanhaProdutos
                    fechar={() => setVisualizarProdutos(false)}
                    vincularProdutos={() => {
                        setVincularProdutos(true)
                        setVisualizarProdutos(false)
                    }}
                    id={idCampanha}
                />
            )}
            </div>
            </div>
        </div>
        </div>
    )
}

