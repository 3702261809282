import styled from 'styled-components';

export const TR = styled.tr`
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 20px;
    text-align: center;

    .Validacao{
        position: relative;
    }

    @media(max-width:700px){
        border-bottom: 3px solid #DDD;
        display: block;
        margin-bottom: .65em;
    }
    
    .detalhes{
        position: absolute;
        left: -73px;
        background-color: #FFFFFF;
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        z-index: 1;
        width: 250px;
        padding: 10px;
    }

    ul{
        margin-top: 8px;
    }
`;

export const TD = styled.td`
    > button {
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    &.preco {
        > button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            padding: 2px;
            border-radius: 20px;
            border: 1px solid #29C013;
        }
    }

    @media(max-width:700px){
        border-bottom: 1px solid #DDD;
        display: block;
        font-size: .8em;
        text-align: right;

        &::before{
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
`;

export const SpanStatus = styled.span`
    padding: 3px;
    border-radius: 3px;
    color: #FFF;
`;