import { useContext, useEffect, useState } from "react";
import { UploadXlsxForm } from "./estilo";
import { BsUpload } from "react-icons/bs";
import { AuthContext } from "../../contexts/auth";
import { UploadXlsxContext } from "../../contexts/uploadXlsx";
import * as XLSX from "xlsx/xlsx.mjs";
import { toast } from "react-toastify";

export default function UploadXlsx({resultCallback, planilhaDeExemplo, setProdutos, produtos, botaoReferencia}){
    const {register,handleSubmit,reset,errors} = useContext(UploadXlsxContext);
    const {user} = useContext(AuthContext);

    const [xlsxFile, setXlsxFile] = useState('');
    const [xlsxJson, setXlsxJson] = useState({});

    async function enviarArquivo(data){
        if (xlsxJson){
            if(produtos && !produtos.data){
                return toast.error('Data de atualização não informada! 🤚');
            }
            resultCallback({data: xlsxJson});
            setXlsxFile('');
            reset();
        }
    }

    const readUploadFile = (e) => {
        e.preventDefault();
        setXlsxFile(e.target.files[0])
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setXlsxJson(json);
                if(setProdutos){
                    setProdutos({...produtos, itens:json});
                }
            };
            if(e.target.files[0]){
                reader.readAsArrayBuffer(e.target.files[0]);
            }
        }
    }
    return (
        <UploadXlsxForm onSubmit={handleSubmit(enviarArquivo)}>
            <div className="container">
                <h6>Importar Planilha XLSX</h6>
                <div className="input-container">
                    <label htmlFor="arquivo-xlsx">
                        <BsUpload />
                    </label>
                    {xlsxFile 
                        ? <p>{xlsxFile.name}</p>
                        : <p>Escolher arquivo</p>
                    }
                    <input 
                        id="arquivo-xlsx"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        {
                            ...register('xlsxFile',{
                                onChange:readUploadFile,
                            })
                        }
                    />
                </div>
                {errors.xlsxFile && <p style={{color: "red"}}>{errors.xlsxFile.message}</p>}
                <div className="button-container">
                    <button type='submit' className="enviarPlanilha" ref={botaoReferencia && botaoReferencia}>ENVIAR</button>
                    <a href={planilhaDeExemplo} download>BAIXAR</a>
                </div>
            </div>
        </UploadXlsxForm>
    )
}