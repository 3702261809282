import {useState, createContext, useEffect, useContext} from 'react';

export const LojaContext = createContext({});

export default function LojaProvider({children}){
    const [lojaInfo, setLojaInfo] = useState({});

    const getLoja = () => {
        let info = localStorage.getItem('dataLoja')
        
        if (info) {
            info = JSON.parse(info);
            setLojaInfo(info)
        }
    }

    const setLoja = (loja) => {
        setLojaInfo(loja);
        localStorage.setItem('dataLoja', JSON.stringify(loja));
    }

    useEffect(()=>{    
        getLoja()
    }, [])

    return(
        <LojaContext.Provider
            value={{
                loja: lojaInfo,
                setLoja
            }}>
            {children}
        </LojaContext.Provider>
    )
}

export const useLoja = () => useContext(LojaContext);
