import { useState,useEffect,useContext} from 'react';
import {IoClose} from 'react-icons/io5';
import {AuthContext} from '../../../contexts/auth';
import api from '../../../services/api';
import {toast} from 'react-toastify';
import InputPesquisa from '../../InputPesquisa';
import {ContainerNull,ContainerTabela,Tabela,Thead,TR,TH,Tbody,TD,SpanStatus, Container} from './estilo';
import {IoAlertCircleOutline} from 'react-icons/io5';
import VincularCampanhasProdutosImport from '../VincularCampanhasProdutosImport';

export default function VincularCampanhasProdutos({ id, fechar, voltar }){
    const [produtos,setProdutos] = useState([])
    const [produtosFiltrados,setProdutosFiltrados] = useState([]);
    const [produtosCampanha,setProdutosCampanha] = useState([]);
    
    const [selecionados,setSelecionados] = useState([]);

    const [load,setLoad] = useState(false);
    const {user,signOut} = useContext(AuthContext);

    const importar = (dados) => {
        const prodSelecionados = produtos.filter(prod => (
            dados.some(dado => String(dado.codigo) === String(prod.codigo)) || 
            dados.some(dado => String(dado.id) === String(prod.id))
        )).map(prod => String(prod.id));

        setSelecionados(prodSelecionados);
    }

    const selecionar = (id) => {
        id = String(id);

        setSelecionados(e => [...e, id]);
    }

    const remover = (id) => {
        id = String(id);

        setSelecionados(e => e.filter(item => item !== id));
    }

    //BUSCA PRODUTOS
    const getProdutos = () => {
        setLoad(true)
        api.get('/produtos',
            {
                headers:{'Authorization':user.token},
            },
        ).then((resp)=>{
            const lista = resp.data.produtos.filter((produto) => produto.status === 'ATIVO')

            setProdutos(lista)
            setProdutosFiltrados(lista)
            setLoad(false)
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoad(false)
            }
        })
    }

    //BUSCA PRODUTOS
    const getProdutosDaCampanha = () => {
        api.get(`/campanhas/${id}/produtos`,
            {
                headers:{'Authorization':user.token},
            },
        ).then((resp) => {
            setProdutosCampanha(resp.data.produtos.map((produto) => String(produto.id)))
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
            }
        })
    }

    useEffect(()=>{
        getProdutos()
        getProdutosDaCampanha()
    // eslint-disable-next-line
    }, [user.token,signOut])

    //VINCULAR iTEM AO PEDIDO
    const salvar = () => {
        setLoad(true)

        api.post(`/campanhas/${id}/produtos`,
            {
                produtosId: selecionados,
                usuarioId: user.id_usuario
            },
            {
                headers:{ 'Authorization': user.token }
            }
        ).then((resp)=>{
            toast.success(resp.data.mensagem)
            voltar();
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.info(error.response.data.error)
                setLoad(false)
            }
        })
    }

    if (load === true) {
        return(
            <div className='modalPrecificador'>
                <div className='modalPrecificador-container'>
                    <h1>Carregando...</h1>
                </div>
            </div>
        )
    }

    function filtroDeProdutos(lista, valor){
        return lista.filter((produto) => (
            produto.produto.toUpperCase().includes(valor.toUpperCase()) ||
            String(produto.codigo ?? '').toUpperCase().includes(valor.toUpperCase()) || 
            produto.categoria.toUpperCase().includes(valor.toUpperCase())
        ));
    }

    return(
        <div className='modalPrecificador'>
            <div className='modalPrecificador-fechar'>
                <button
                    onClick={fechar}
                    className='modalPrecificador-btn-fechar'
                    type='button'
                >
                    <IoClose size={30} color='#FFF'/>
                </button>
            </div>
            <div className='modalPrecificador-container'>
                <Container>
                    <h2>Adicionar Itens</h2>

                    <div className='pesquisaContainer'>
                        <h3>Pesquisar</h3>
                        <div className='input-pesquisa-container'>
                            <InputPesquisa
                                lista={produtos}
                                setLista={setProdutosFiltrados}
                                funcaoDeFiltro={filtroDeProdutos}
                            />
                        </div>
                    </div>

                    <VincularCampanhasProdutosImport
                        setDados={importar}
                    />

                    {produtosFiltrados.filter(produto => !produtosCampanha.includes(String(produto.id))).length === 0 ? (
                        <ContainerNull>
                            <IoAlertCircleOutline size={50} color='#FF0000'/>
                            <h1>Nenhum produto encontrado...</h1>
                        </ContainerNull>
                    ) : (
                        <ContainerTabela>
                            <Tabela>
                                <Thead>
                                    <TR>
                                        <TH scope='col'>Id</TH>
                                        <TH scope='col'>Codigo</TH>
                                        <TH scope='col'>Produto</TH>
                                        <TH scope='col'>Categoria</TH>
                                        <TH scope='col'>Status</TH>
                                        <TH scope='col'>{selecionados.length} selecionados</TH>
                                    </TR>
                                </Thead>
                                <Tbody>
                                    {produtosFiltrados.filter(produto => !produtosCampanha.includes(String(produto.id))).map((produto)=> (
                                        <TR key={produto.id}>
                                            <TD data-label='id'>{produto.id}</TD>
                                            <TD data-label='codigo'>{produto.codigo}</TD>
                                            <TD data-label='nome'>{produto.produto}</TD>
                                            <TD data-label='categoria'>{produto.categoria}</TD>
                                            <TD data-label='status'>
                                                <SpanStatus
                                                    style={{
                                                        backgroundColor: produto.status === 'ATIVO' ? '#29C013' : '#FF0000'
                                                    }}
                                                >{produto.status}</SpanStatus>
                                            </TD>
                                            <TD data-label='#'>
                                                <input
                                                    type='checkbox'
                                                    checked={selecionados.includes(String(produto.id))}
                                                    onChange={(e) => {
                                                        if (e.target.checked) selecionar(produto.id);
                                                        else remover(produto.id);
                                                    }}
                                                />
                                            </TD>
                                        </TR>
                                    ))}
                                </Tbody>
                            </Tabela>
                        </ContainerTabela>
                    )}

                    <div className='modalPrecificador-button'>
                        <button
                            onClick={voltar}
                            type='button'
                        >VOLTAR</button>

                        <button
                            onClick={() => setSelecionados([])}
                            type='button'
                        >LIMPAR</button>

                        <button
                            onClick={salvar}
                            type='button'
                        >SALVAR</button>
                    </div>
                </Container>
            </div>
        </div>
    )
}