import axios from "axios";

export const apiUrl = 
    // 'https://swifttrade-teste.azurewebsites.net/'
    'https://apiswifttrade.ifollow.com.br'
    // 'http://localhost:8080'

const api = axios.create({
    baseURL: apiUrl,
})

export default api;
