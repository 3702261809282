import { useEffect, useState, useContext, useCallback } from 'react';
import {
    ContainerNull,
    Container,
    ContainerProdutos,
    Titulo,
    AreaHeader,
    AreaPesquisa,
    ButtonNovo,
    ContainerTabela,
    Tabela,
    Thead,
    TR,
    TH,
    Tbody,
    TD,
    SpanStatus,
    ButtonEditar
} from './estilo';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../contexts/auth';
import api from '../../../services/api';
import InputPesquisa from '../../InputPesquisa';
import {IoAlertCircleOutline} from 'react-icons/io5';

export default function Campanhasdash(){
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(true);
    const [campanhas,setCampanhas] = useState([]);
    const [campanhasFiltradas, setCampanhasFiltradas] = useState([]);

    const buscaCampanhas = () => {
        setLoading(true)
        api.get('/campanhas',
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setCampanhas(resp.data.campanhas)
            setCampanhasFiltradas(resp.data.campanhas)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    };
    
    useEffect(() => {
        buscaCampanhas()
    }, []) 

    function filtrar(lista, valor) {
        return lista.filter((campanha) => (
            campanha.nome.toUpperCase().includes(valor.toUpperCase()) ||
            String(campanha.id).toUpperCase().includes(valor)
        ));
    }

    if (loading) return (
        <Container>
            <ContainerProdutos>
                <Titulo>Carregando...</Titulo>
            </ContainerProdutos>
        </Container>
    )

    return(
        <Container>
            <ContainerProdutos>
                <Titulo>LISTA DE CAMPANHAS</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa
                            lista={campanhas}
                            setLista={setCampanhasFiltradas}
                            funcaoDeFiltro={filtrar}
                        />
                    </AreaPesquisa>

                    <ButtonNovo to='campanhas/novo'>
                        Novo
                    </ButtonNovo>
                </AreaHeader>

                {campanhasFiltradas.length === 0 ? (
                    <ContainerNull>
                        <IoAlertCircleOutline size={50} color='#FF0000'/>
                        <h1>Nenhuma campanha encontrada...</h1>
                    </ContainerNull>
                ) : (
                    <ContainerTabela>
                        <Tabela>
                            <Thead>
                                <TR>
                                    <TH scope='col'>Id</TH>
                                    <TH scope='col'>Nome</TH>
                                    <TH scope='col'>Data de inicio</TH>
                                    <TH scope='col'>Data de fim</TH>
                                    <TH scope='col'>#</TH>
                                </TR>
                            </Thead>
                                <Tbody>
                                    {campanhasFiltradas.map((campanha)=> (
                                        <TR key={campanha.id}>
                                            <TD data-label='id'>{campanha.id}</TD>
                                            <TD data-label='campanha'>{campanha.nome}</TD>
                                            <TD data-label='data de inicio'>{campanha.dataInicio}</TD>
                                            <TD data-label='data de fim'>{campanha.dataFim}</TD>
                                            <TD data-label='#'>
                                                <ButtonEditar to={`campanhas/editar/${campanha.id}`}>
                                                    EDITAR
                                                </ButtonEditar>
                                            </TD>
                                        </TR>
                                    ))}
                                </Tbody>
                        </Tabela>
                    </ContainerTabela>
                )}
            </ContainerProdutos>
        </Container>
    )
}