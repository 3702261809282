import UploadXlsx from "../../UploadXlsx";
import {Container, TableContainer} from "./estilo";
import editarProdutosXlsx from "../../../planilhasDeExemplo/atualizacao_de_produtos_programada.xlsx";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { useContext, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/auth";

export const AtualizacaoProgramadaModal = ({setModal}) => {
    const {user,signOut} = useContext(AuthContext);
    const [errorsMsg, setErrorsMsg] = useState({});
    const [produtos, setProdutos] = useState({itens:[], data: '', nome: "", email: user.email});
    const botaoReferencia = useRef(".enviarPlanilha");
    let validado = true

    function enviarDados () {
        if(!validado){
            return toast.error('Corrija os campos inválidos.');
        }

        if(produtos.itens.length === 0) {
            return toast.error('Adicione produtos para enviar.');
        } else if(produtos.data === '') {
            return toast.error('Adicione uma data para enviar.');
        } else if(Date.parse(produtos.data) < Date.now() ) {
            return toast.error('Data inválida.');
        }

        api.patch('/produtos/atualizacao/programada', {produtos}, {
            headers:{
                'Authorization': user.token
            }
        }).then((resp) => {
            window.location.reload()
            toast.success(resp.data.mensagem)
        }).catch((error) => {
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            } else if (error.response.status === 406) {
                toast.info(error.response.data.error)
            } else if (error.response.status === 413){
                toast.info('Quantidade de dados muito grande.');
            } else {
                toast.info("Erro ao editar produtos.");
                setErrorsMsg(error.response.data.errors);
            }
        })
    }

    function validarCampos(produto){
        let error = {}

        if(!produto.codigo){
            error.codigo = 'Campo obrigatório.'
        }else if(produto.codigo.length > 45){
            error.codigo = 'Máximo de 20 caracteres.'
        }

        if(produto.produto?.length > 50){
            error.produto = 'Máximo de 50 caracteres.'
        }

        if(produto.precoAC && isNaN(Number(produto.precoAC))){
            error.precoAC = 'Precisa ser um número.'
        }

        if(produto.precoAL && isNaN(Number(produto.precoAL))){
            error.precoAL = 'Precisa ser um número.'
        }

        if(produto.precoAP && isNaN(Number(produto.precoAP))){
            error.precoAP = 'Precisa ser um número.'
        }

        if(produto.precoAM && isNaN(Number(produto.precoAM))){
            error.precoAM = 'Precisa ser um número.'
        }

        if(produto.precoBA && isNaN(Number(produto.precoBA))){
            error.precoBA = 'Precisa ser um número.'
        }

        if(produto.precoCE && isNaN(Number(produto.precoCE))){
            error.precoCE = 'Precisa ser um número.'
        }

        if(produto.precoDF && isNaN(Number(produto.precoDF))){
            error.precoDF = 'Precisa ser um número.'
        }

        if(produto.precoES && isNaN(Number(produto.precoES))){
            error.precoES = 'Precisa ser um número.'
        }

        if(produto.precoGO && isNaN(Number(produto.precoGO))){
            error.precoGO = 'Precisa ser um número.'
        }

        if(produto.precoMA && isNaN(Number(produto.precoMA))){
            error.precoMA = 'Precisa ser um número.'
        }

        if(produto.precoMT && isNaN(Number(produto.precoMT))){
            error.precoMT = 'Precisa ser um número.'
        }

        if(produto.precoMS && isNaN(Number(produto.precoMS))){
            error.precoMS = 'Precisa ser um número.'
        }

        if(produto.precoMG && isNaN(Number(produto.precoMG))){
            error.precoMG = 'Precisa ser um número.'
        }

        if(produto.precoPA && isNaN(Number(produto.precoPA))){
            error.precoPA = 'Precisa ser um número.'
        }

        if(produto.precoPB && isNaN(Number(produto.precoPB))){
            error.precoPB = 'Precisa ser um número.'
        }

        if(produto.precoPR && isNaN(Number(produto.precoPR))){
            error.precoPR = 'Precisa ser um número.'
        }

        if(produto.precoPE && isNaN(Number(produto.precoPE))){
            error.precoPE = 'Precisa ser um número.'
        }

        if(produto.precoPI && isNaN(Number(produto.precoPI))){
            error.precoPI = 'Precisa ser um número.'
        }

        if(produto.precoRN && isNaN(Number(produto.precoRN))){
            error.precoRN = 'Precisa ser um número.'
        }

        if(produto.precoRS && isNaN(Number(produto.precoRS))){
            error.precoRS = 'Precisa ser um número.'
        }

        if(produto.precoRO && isNaN(Number(produto.precoRO))){
            error.precoRO = 'Precisa ser um número.'
        }

        if(produto.precoRR && isNaN(Number(produto.precoRR))){
            error.precoRR = 'Precisa ser um número.'
        }

        if(produto.precoSC && isNaN(Number(produto.precoSC))){
            error.precoSC = 'Precisa ser um número.'
        }

        if(produto.precoSP && isNaN(Number(produto.precoSP))){
            error.precoSP = 'Precisa ser um número.'
        }

        if(produto.precoSE && isNaN(Number(produto.precoSE))){
            error.precoSE = 'Precisa ser um número.'
        }

        if(produto.precoTO && isNaN(Number(produto.precoTO))){
            error.precoTO = 'Precisa ser um número.'
        }

        if(produto.precoRJ && isNaN(Number(produto.precoRJ))){
            error.precoRJ = 'Precisa ser um número.'
        }

        if(produto.preco_sp_guanabara && isNaN(Number(produto.preco_sp_guanabara))){
            error.preco_sp_guanabara = 'Precisa ser um número.'
        }

        if(produto.preco_sp_fisica && isNaN(Number(produto.preco_sp_fisica))){
            error.preco_sp_fisica = 'Precisa ser um número.'
        }

        if(produto.preco_df_fisica && isNaN(Number(produto.preco_df_fisica))){
            error.preco_df_fisica = 'Precisa ser um número.'
        }

        if(produto.preco_df2_fisica && isNaN(Number(produto.preco_df2_fisica))){
            error.preco_df2_fisica = 'Precisa ser um número.'
        }

        if(produto.preco_go_fisica && isNaN(Number(produto.preco_go_fisica))){
            error.preco_go_fisica = 'Precisa ser um número.'
        }

        if(produto.preco_rj_fisica && isNaN(Number(produto.preco_rj_fisica))){
            error.preco_rj_fisica = 'Precisa ser um número.'
        }

        if(produto.preco_anapolis_fisica && isNaN(Number(produto.preco_anapolis_fisica))){
            error.preco_anapolis_fisica = 'Precisa ser um número.'
        }

        if(produto.descricao?.length > 50){
            error.descricao = 'Máximo de 50 caracteres.'
        }

        if(produto.descricao2?.length > 50){
            error.descricao2 = 'Máximo de 50 caracteres.'
        }

        if(produto.peso?.length > 50){
            error.peso = 'Máximo de 50 caracteres.'
        }

        if(produto.frase_promocional?.length > 45){
            error.frase_promocional = 'Máximo de 45 caracteres.'
        }

        if(produto.unidade_de_medida_promocional?.length > 45){
            error.unidade_de_medida_promocional = 'Máximo de 45 caracteres.'
        }

        if(produto.alcoolico && produto.alcoolico != 1 && produto.alcoolico != 0){
            error.alcoolico = 'Campo inválido, insira 1 para sim e 0 para não.'
        }

        if(produto.status && (produto.status != "ATIVO" && produto.status != "DESATIVADO")){
            error.status = 'Campo inválido, insira ATIVO ou DESATIVADO'
        }
        console.log(error)
        return error
    }

    const handleSimulateClick = () => {
        botaoReferencia.current?.click();
      };
    
    return (
        <Container>
            <div className="divBranca">
                <div className="header">
                    <h2>
                        Atualizacao Programada
                    </h2>
                    <span className="close" onClick={() => setModal(false)}>X</span>
                </div>
                <div className="width100">
                    <UploadXlsx resultCallback={enviarDados} planilhaDeExemplo={editarProdutosXlsx} setProdutos={setProdutos} produtos={produtos} botaoReferencia={botaoReferencia}/>
                    <div className="column">
                        <h3>Data Atualização:</h3>
                        <input className="input" type="datetime-local" name="data" id="data" onChange={(e) => setProdutos(() => ({...produtos, data: e.target.value}))}/>
                    </div>
                    <div className="column">
                        <h3>Nome Atualização:</h3>
                        <input className="input" type="text" name="nome" id="nome" onChange={(e) => setProdutos(() => ({...produtos, nome: e.target.value}))}/>
                    </div>
                    <div>
                        <button className="botaoRef" onClick={() => handleSimulateClick()}>SALVAR</button>
                    </div>
                </div>
                {
                    produtos.itens.length > 0 && 
                    <TableContainer>
                        <table>
                            <thead>
                                <tr>
                                <th>Aprovado</th>
                                <th>codigo</th>
                                <th>produto</th>
                                <th>precoSP_barbosa</th>
                                <th>precoAC</th>
                                <th>precoAL</th>
                                <th>precoAP</th>
                                <th>precoAM</th>
                                <th>precoBA</th>
                                <th>precoCE</th>
                                <th>precoDF</th>
                                <th>precoES</th>
                                <th>precoGO</th>
                                <th>precoMA</th>
                                <th>precoMT</th>
                                <th>precoMS</th>
                                <th>precoMG</th>
                                <th>precoPA</th>
                                <th>precoPB</th>
                                <th>precoPR</th>
                                <th>precoPE</th>
                                <th>precoPI</th>
                                <th>precoRN</th>
                                <th>precoRS</th>
                                <th>precoRO</th>
                                <th>precoRR</th>
                                <th>precoSC</th>
                                <th>precoSP</th>
                                <th>precoSE</th>
                                <th>precoTO</th>
                                <th>precoRJ</th>
                                <th>preco_sp_guanabara</th>
                                <th>preco_sp_fisica</th>
                                <th>preco_df_fisica</th>
                                <th>preco_df2_fisica</th>
                                <th>preco_go_fisica</th>
                                <th>preco_rj_fisica</th>
                                <th>preco_anapolis_fisica</th>
                                <th>descricao</th>
                                <th>descricao2</th>
                                <th>peso</th>
                                <th>status</th>
                                <th>categoriaId</th>
                                <th>tipoLoja_id</th>
                                <th>frase_promocional</th>
                                <th>unidade_de_medida_promocional</th>
                                <th>alcoolico</th>
                                </tr>
                            </thead>
                                <tbody>
                                    {produtos.itens.map((produto)=> 
                                        {
                                            const errors = validarCampos(produto)
                                            if(Object.keys(errors).length > 0){
                                                validado = false
                                            }

                                            return(
                                                <tr key={produto.id}>
                                                    <td>
                                                        {Object.keys(errors).length > 0 ? 
                                                            <img className="validacao" src="https://istockblobs.blob.core.windows.net/site/multiply.png"/> : 
                                                            <img className="validacao" src="https://istockblobs.blob.core.windows.net/site/check.png"/>}  
                                                    </td>
                                                <td>
                                                    {produto.codigo}
                                                    {errors.codigo && <span className="alerta">({errors.codigo})</span>}
                                                </td>
                                                <td>{produto.produto}</td>
                                                <td>{produto.precoSP_barbosa}
                                                    {errors.precoSP_barbosa && <span className="alerta">({errors.precoSP_barbosa})</span>}
                                                </td>
                                                <td>{produto.precoAC}
                                                    {errors.precoAC && <span className="alerta">({errors.precoAC})</span>}
                                                </td>
                                                <td>{produto.precoAL}
                                                    {errors.precoAL && <span className="alerta">({errors.precoAL})</span>}
                                                </td>
                                                <td>{produto.precoAP}
                                                    {errors.precoAP && <span className="alerta">({errors.precoAP})</span>}
                                                </td>
                                                <td>{produto.precoAM}
                                                    {errors.precoAM && <span className="alerta">({errors.precoAM})</span>}
                                                </td>
                                                <td>{produto.precoBA}
                                                    {errors.precoBA && <span className="alerta">({errors.precoBA})</span>}
                                                </td>
                                                <td>{produto.precoCE}
                                                    {errors.precoCE && <span className="alerta">({errors.precoCE})</span>}
                                                </td>
                                                <td>{produto.precoDF}
                                                    {errors.precoDF && <span className="alerta">({errors.precoDF})</span>}
                                                </td>
                                                <td>{produto.precoES}
                                                    { errors.precoES && <span className="alerta">({errors.precoES})</span>}
                                                </td>
                                                <td>{produto.precoGO}
                                                    {errors.precoGO && <span className="alerta">({errors.precoGO})</span>}
                                                </td>
                                                <td>{produto.precoMA}
                                                    { errors.precoMA && <span className="alerta">({errors.precoMA})</span>}
                                                </td>
                                                <td>{produto.precoMT}
                                                    { errors.precoMT && <span className="alerta">({errors.precoMT})</span>}
                                                </td>
                                                <td>{produto.precoMS}
                                                    { errors.precoMS && <span className="alerta">({errors.precoMS})</span>}
                                                </td>
                                                <td>{produto.precoMG}
                                                    { errors.precoMG && <span className="alerta">({errors.precoMG})</span>}
                                                </td>
                                                <td>{produto.precoPA}
                                                    { errors.precoPA && <span className="alerta">({errors.precoPA})</span>}
                                                </td>
                                                <td>{produto.precoPB}
                                                    { errors.precoPB && <span className="alerta">({errors.precoPB})</span>}
                                                </td>
                                                <td>{produto.precoPR}
                                                    { errors.precoPR && <span className="alerta">({errors.precoPR})</span>}
                                                </td>
                                                <td>{produto.precoPE}
                                                    { errors.precoPE && <span className="alerta">({errors.precoPE})</span>}
                                                </td>
                                                <td>{produto.precoPI}
                                                    { errors.precoPI && <span className="alerta">({errors.precoPI})</span>}
                                                </td>
                                                <td>{produto.precoRN}
                                                    { errors.precoRN && <span className="alerta">({errors.precoRN})</span>}
                                                </td>
                                                <td>{produto.precoRS}
                                                    { errors.precoRS && <span className="alerta">({errors.precoRS})</span>}
                                                </td>
                                                <td>{produto.precoRO}
                                                    { errors.precoRO && <span className="alerta">({errors.precoRO})</span>}
                                                </td>
                                                <td>{produto.precoRR}
                                                    { errors.precoRR && <span className="alerta">({errors.precoRR})</span>}
                                                </td>
                                                <td>{produto.precoSC}
                                                    { errors.precoSC && <span className="alerta">({errors.precoSC})</span>}
                                                </td>
                                                <td>{produto.precoSP}
                                                    { errors.precoSP && <span className="alerta">({errors.precoSP})</span>}
                                                </td>
                                                <td>{produto.precoSE}
                                                    { errors.precoSE && <span className="alerta">({errors.precoSE})</span>}
                                                </td>
                                                <td>{produto.precoTO}
                                                    { errors.precoTO && <span className="alerta">({errors.precoTO})</span>}
                                                </td>
                                                <td>{produto.precoRJ}
                                                    { errors.precoRJ && <span className="alerta">({errors.precoRJ})</span>}
                                                </td>
                                                <td>{produto.preco_sp_guanabara}
                                                    { errors.preco_sp_guanabara && <span className="alerta">({errors.preco_sp_guanabara})</span>}
                                                </td>
                                                <td>{produto.preco_sp_fisica}
                                                    { errors.preco_sp_fisica && <span className="alerta">({errors.preco_sp_fisica})</span>}
                                                </td>
                                                <td>{produto.preco_df_fisica}
                                                    { errors.preco_df_fisica && <span className="alerta">({errors.preco_df_fisica})</span>}
                                                </td>
                                                <td>{produto.preco_df2_fisica}
                                                    { errors.preco_df2_fisica && <span className="alerta">({errors.preco_df2_fisica})</span>}
                                                </td>
                                                <td>{produto.preco_go_fisica}
                                                    { errors.preco_go_fisica && <span className="alerta">({errors.preco_go_fisica})</span>}
                                                </td>
                                                <td>{produto.preco_rj_fisica}
                                                    { errors.preco_rj_fisica && <span className="alerta">({errors.preco_rj_fisica})</span>}
                                                </td>
                                                <td>{produto.preco_anapolis_fisica}
                                                    { errors.preco_anapolis_fisica && <span className="alerta">({errors.preco_anapolis_fisica})</span>}
                                                </td>
                                                <td>{produto.descricao}
                                                    { errors.descricao && <span className="alerta">({errors.descricao})</span>}
                                                </td>
                                                <td>{produto.descricao2}
                                                    { errors.descricao2 && <span className="alerta">({errors.descricao2})</span>}
                                                </td>
                                                <td>{produto.peso}
                                                    { errors.peso && <span className="alerta">({errors.peso})</span>}
                                                </td>
                                                <td>{produto.status}
                                                    { errors.status && <span className="alerta">({errors.status})</span>}
                                                </td>
                                                <td>{produto.categoriaId}</td>
                                                <td>{produto.tipoLoja_id}
                                                    { errors.tipoLoja_id && <span className="alerta">({errors.tipoLoja_id})</span>}
                                                </td>
                                                <td>{produto.frase_promocional}
                                                    { errors.frase_promocional && <span className="alerta">({errors.frase_promocional})</span>}
                                                </td>
                                                <td>{produto.unidade_de_medida_promocional}
                                                    { errors.unidade_de_medida_promocional && <span className="alerta">({errors.unidade_de_medida_promocional})</span>}
                                                </td>
                                                <td>{produto.alcoolico} 
                                                    { errors.alcoolico && <span className="alerta">({errors.alcoolico})</span>}
                                                </td>
                                                <td>{produto.createdAt} 
                                                    { errors.createdAt && <span className="alerta">({errors.createdAt})</span>}
                                                </td>
                                                <td>{produto.updatedAt}
                                                    { errors.updatedAt && <span className="alerta">({errors.updatedAt})</span>}
                                                </td>
                                            </tr>)
                                            }
                                        )}
                                </tbody>
                        </table>
                    </TableContainer>
                }
            </div>
        </Container>
    )
}