import Campanhasdash from "../../../components/Dash/Campanhasdash";
import HeaderLateral from "../../../components/HeaderLateral";
import { Helmet } from 'react-helmet';

export default function Campanhas(){
    return (
        <div className="solicitarcontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Campanhas</title>
            </Helmet>
            <HeaderLateral />
            <Campanhasdash />
        </div>
    )
}