import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../contexts/auth";
import api from "../../../services/api";
import { VscFilePdf } from "react-icons/vsc";
import { ButtonEditar, ButtonFechar, ButtonSalvar, InputObs, SelectTd, StatusSelect, StatusTd, TR } from "./estilo";

export default function PedidoTr({pedido,buscaPedidos,Admin}){
    const {user,signOut} = useContext(AuthContext);
    const [editarStatus,setEditarStatus] = useState(false);
    const [statusFornecedor, setStatusFornecedor] = useState(pedido.statusFornecedor);
    const [observacao, setObservacao] = useState('');
    const [PDFLink, setPDFLink] = useState('');
    const componentMounted = useRef(true); 

    const verificaPDF = useCallback(() => {
        const [dia, mes, ano] = String(pedido.createdAt).split('/');
        const dataDoPDF = [ano, mes, dia].join('-');
        const horaDoPDF = pedido.createdtimeAt;

        const agora = new Date();
        const milisegundosPorDia = 1000 * 60 * 60 * 24;
        const PDFDate = new Date(dataDoPDF + ' ' + horaDoPDF);
        const dataDoPDFEmMilisegundos = (agora.getTime() - PDFDate.getTime());
        const dataDOPDFEmDias = Math.floor(dataDoPDFEmMilisegundos / milisegundosPorDia);

        if (dataDOPDFEmDias < 2){
            setPDFLink(`https://apiswifttrade.ifollow.com.br/uploads/ID${pedido.id}.pdf`)
        }
    },[pedido.createdAt, pedido.createdtimeAt, pedido.id]);
    useEffect(() => {
        if (componentMounted.current){ 
            verificaPDF();
        }
        return () => {
            componentMounted.current = false;
        }
    },[verificaPDF])

    function salvarStatus(){
        api.patch('/pedidos',{
            statusFornecedor,
            observacao,
            pedido_id:pedido.id,
        },{
            headers:{
                Authorization: user.token,
            },
        }).then((res) => {
            window.location.reload()
        }).catch((error) => {
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
            }
        })
    }
    
    return (
        <TR key={pedido.id}>
            {Admin ? (
                <td data-label='codigo-loja'>{pedido.idPedido}</td>
            ) : (
                <td data-label='id'>{pedido.id}</td>
            )}
             <td data-label='codigo-loja'>{pedido.codigoLoja}</td>
            {editarStatus && !Admin
                    ? (
                        <>
                            <SelectTd>
                                <StatusSelect value={statusFornecedor} onChange={(e) => setStatusFornecedor(e.target.value)}>
                                    <option value=''>Selecionar</option>
                                    <option value='AGUARDANDO'>AGUARDANDO</option>
                                    <option value='PRODUCAO'>PRODUÇÃO</option>
                                    <option value='PRONTO'>PRONTO</option>
                                    <option value='ENTREGUE'>ENTREGUE</option>
                                    <option value='RETIRADO'>RETIRADO</option>
                                </StatusSelect>
                            </SelectTd>
                            <td>
                                <InputObs data-label='obs' placeholder="observação" value={observacao} onChange={(e) => setObservacao(e.target.value)}/>
                            </td>
                        </>
                )
                : (
                    <StatusTd data-label='statusFornecedor' status={pedido.statusFornecedor}>{pedido.statusFornecedor}</StatusTd>   
                )
            }
            {!Admin && <td data-label='obs' style={{display: `${editarStatus ? 'none' : 'block'}`}}>{pedido.obs}</td>}
            {!Admin && <td data-label='estado'>{pedido.estado}</td>}
            {Admin && <td data-label='tipo-entrega'>{pedido.tipoEntrega}</td>}
            {Admin ? (
                <td data-label='paginas'>{pedido.quantidadePaginas}</td>
            ) : (
                <td data-label='paginas'>{pedido.paginas}</td>
            )}
            {!Admin &&    
                <td data-label='PDF'>
                    {PDFLink 
                        ? (
                            <a target='_blank' rel='noreferrer' href={PDFLink}><VscFilePdf size='30' color='red'/></a>
                        ) : (
                            <VscFilePdf size='30' color='gray' style={{cursor: 'not-allowed'}}/>
                        )
                    }
                </td>
            }
            {!Admin &&
                <td data-label='#'>
                    {editarStatus 
                        ? (
                            <>
                                <ButtonSalvar onClick={salvarStatus}>
                                    SALVAR
                                </ButtonSalvar>
                                <ButtonFechar onClick={() => setEditarStatus(false)}>X</ButtonFechar>
                            </>
                        )
                        : <ButtonEditar onClick={()=> setEditarStatus(true)}>
                            EDITAR
                        </ButtonEditar>
                    }
                </td>
            }
            {Admin && (
                <>
                    <td data-label='loja'>{pedido.campanha}</td>
                    <td data-label='loja'>{pedido.loja}</td>
                    <td data-label='formato'>{pedido.formato}</td>
                </>
            )}
        </TR>
    )
}