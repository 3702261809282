import {IoClose} from 'react-icons/io5';
import { Container, SaveButton } from './estilo';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth';

export default function CampanhaProdutoPrecos({ fechar, getProdutoPrecos, atualizarProdutoPrecos }){
    const { register, getValues, handleSubmit, reset } = useForm();

    const { signOut, user } = useAuth();

    const carregarPrecos = async () => {
        try {
            const resposta = await getProdutoPrecos()
            const produto = resposta.data.produto

            let precos = {
                id: produto.id,
                nome: produto.nome
            }

            for (const key of Object.keys(produto)) {
                if (key.startsWith('preco') && typeof produto[key] === 'number') {
                    precos[key] = produto[key].toFixed(2)
                }
            }

            reset(precos)
        } catch (error) {
            if(error.response.status === 401) signOut();

            toast.error(error.response.data.error || 'Erro ao carregar preços')
        }
    }

    const atualizarPrecos = async (data) => {
        try {
            data.usuario_id = user.id_usuario

            await atualizarProdutoPrecos(data)

            toast.success('Preços atualizados com sucesso')
        } catch (error) {
            if(error.response.status === 401) signOut();

            toast.error(error.response.data?.error || 'Erro ao atualizar preços')
        }
    }

    useEffect(() => {
        carregarPrecos();
    }, [])

    return(
        <div className='modalPrecificador'>
            <div className='modalPrecificador-fechar'>
                <button
                    onClick={fechar}
                    className='modalPrecificador-btn-fechar'
                    type='button'
                >
                    <IoClose size={30} color='#FFF'/>
                </button>
            </div>
            <article
                className='modalPrecificador-container'
            >
                <Container>
                    <h2>LOJAS FISICAS</h2>

                    <article className='lista_precos'>
                        <article className='preco'>
                            <label className='titulo'>PRECO SP</label>
                            <input
                                type='number'
                                step={0.01}
                                pattern="^\d*(\.\d{0,2})?$"
                                {...register('precoSP')}
                            />
                            {getValues('precoSPCadastrado') > 0 && <label className='legenda'>Valor atual: {getValues('precoSPCadastrado')}</label>}
                        </article>

                        <article className='preco'>
                            <label className='titulo'>PRECO DF</label>
                            <input
                                type='number'
                                step={0.01}
                                pattern="^\d*(\.\d{0,2})?$"
                                {...register('precoDF')}
                            />
                            {getValues('precoDFCadastrado') > 0 && <label className='legenda'>Valor atual: {getValues('precoDFCadastrado')}</label>}
                        </article>

                        <article className='preco'>
                            <label className='titulo'>PRECO GO</label>
                            <input
                                type='number'
                                step={0.01}
                                pattern="^\d*(\.\d{0,2})?$"
                                {...register('precoGO')}
                            />
                            {getValues('precoGOCadastrado') > 0 && <label className='legenda'>Valor atual: {getValues('precoGOCadastrado')}</label>}
                        </article>

                        <article className='preco'>
                            <label className='titulo'>PRECO RJ</label>
                            <input
                                type='number'
                                step={0.01}
                                pattern="^\d*(\.\d{0,2})?$"
                                {...register('precoRJ')}
                            />
                            {getValues('precoRJCadastrado') > 0 && <label className='legenda'>Valor atual: {getValues('precoRJCadastrado')}</label>}
                        </article>
                    </article>

                    <SaveButton
                        onClick={handleSubmit(atualizarPrecos)}
                        type='button'
                    >SALVAR</SaveButton>
                </Container>
            </article>
        </div>
    )
}