import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import api from "../../services/api"
import HeaderLateral from "../HeaderLateral"
import { Container, TableContainer } from "./estilo"

export const ListaAtualizacaoProgramada = ({planilha, setPlanilha}) => {
    const {user} = useContext(AuthContext);
    const data = planilha.data?.split('T')
    const dataFormatada = data ? data[0]?.split('-').reverse().join('/') : ""
    const hora = data ? data[1].split(':') : ""
    const horaFormatada = hora ? `${hora[0]}:${hora[1]}` : ""

    const deletarAtualizacoes = () => {
        api.delete(`/produtos/atualizacao/programada/${planilha.nome}/${planilha.data}`,
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setPlanilha(null)
        })
        .catch((error)=>{
            console.log(error)
        })
    };

    return (
        planilha && 
        <Container>
            <HeaderLateral/>
            <div className="body">
                <div>
                    <h1>Dados Atualização
                        <span onClick={() => setPlanilha(null)}>X</span>
                    </h1>
                    <div className="space-between">
                        <div>
                        <h2>{planilha.nome}</h2>
                        <div>
                            <p className="data">Atualização programada para o dia {dataFormatada} {horaFormatada}</p>
                        </div>
                        </div>
                        <div>
                            <button onClick={() => deletarAtualizacoes()}>Deletar</button>
                        </div>
                    </div>
                    <TableContainer>
                        <table>
                            <thead>
                                <tr>
                                <th>codigo</th>
                                <th>produto</th>
                                <th>precoSP_barbosa</th>
                                <th>precoAC</th>
                                <th>precoAL</th>
                                <th>precoAP</th>
                                <th>precoAM</th>
                                <th>precoBA</th>
                                <th>precoCE</th>
                                <th>precoDF</th>
                                <th>precoES</th>
                                <th>precoGO</th>
                                <th>precoMA</th>
                                <th>precoMT</th>
                                <th>precoMS</th>
                                <th>precoMG</th>
                                <th>precoPA</th>
                                <th>precoPB</th>
                                <th>precoPR</th>
                                <th>precoPE</th>
                                <th>precoPI</th>
                                <th>precoRN</th>
                                <th>precoRS</th>
                                <th>precoRO</th>
                                <th>precoRR</th>
                                <th>precoSC</th>
                                <th>precoSP</th>
                                <th>precoSE</th>
                                <th>precoTO</th>
                                <th>precoRJ</th>
                                <th>preco_sp_guanabara</th>
                                <th>preco_sp_fisica</th>
                                <th>preco_df_fisica</th>
                                <th>preco_df2_fisica</th>
                                <th>preco_go_fisica</th>
                                <th>preco_rj_fisica</th>
                                <th>preco_anapolis_fisica</th>
                                <th>descricao</th>
                                <th>descricao2</th>
                                <th>peso</th>
                                <th>status</th>
                                <th>categoriaId</th>
                                <th>tipoLoja_id</th>
                                <th>frase_promocional</th>
                                <th>unidade_de_medida_promocional</th>
                                <th>alcoolico</th>
                                </tr>
                            </thead>
                                <tbody>
                                    {planilha.itens && planilha.itens.map((produto)=> 
                                        {
                                            return(
                                                <tr key={produto.id}>
                                                <td>
                                                    {produto.codigo}
                                                </td>
                                                <td>{produto.produto}</td>
                                                <td>
                                                    {produto.precoSP_barbosa}
                                                </td>
                                                <td>
                                                    {produto.precoAC}
                                                </td>
                                                <td>
                                                    {produto.precoAL}
                                                </td>
                                                <td>
                                                    {produto.precoAP}
                                                </td>
                                                <td>
                                                    {produto.precoAM}
                                                </td>
                                                <td>
                                                    {produto.precoBA}
                                                </td>
                                                <td>
                                                    {produto.precoCE}
                                                </td>
                                                <td>
                                                    {produto.precoDF}
                                                </td>
                                                <td>
                                                    {produto.precoES}
                                                </td>
                                                <td>
                                                    {produto.precoGO}
                                                </td>
                                                <td>
                                                    {produto.precoMA}
                                                </td>
                                                <td>
                                                    {produto.precoMT}
                                                </td>
                                                <td>
                                                    {produto.precoMS}
                                                </td>
                                                <td>
                                                    {produto.precoMG}
                                                </td>
                                                <td>
                                                    {produto.precoPA}
                                                </td>
                                                <td>
                                                    {produto.precoPB}
                                                </td>
                                                <td>
                                                    {produto.precoPR}
                                                </td>
                                                <td>
                                                    {produto.precoPE}
                                                </td>
                                                <td>
                                                    {produto.precoPI}
                                                </td>
                                                <td>
                                                    {produto.precoRN}
                                                </td>
                                                <td>
                                                    {produto.precoRS}
                                                </td>
                                                <td>
                                                    {produto.precoRO}
                                                </td>
                                                <td>
                                                    {produto.precoRR}
                                                </td>
                                                <td>
                                                    {produto.precoSC}
                                                </td>
                                                <td>
                                                    {produto.precoSP}
                                                </td>
                                                <td>
                                                    {produto.precoSE}
                                                </td>
                                                <td>
                                                    {produto.precoTO}
                                                </td>
                                                <td>
                                                    {produto.precoRJ}
                                                </td>
                                                <td>
                                                    {produto.preco_sp_guanabara}
                                                </td>
                                                <td>
                                                    {produto.preco_sp_fisica}
                                                </td>
                                                <td>
                                                    {produto.preco_df_fisica}
                                                </td>
                                                <td>
                                                    {produto.preco_df2_fisica}
                                                </td>
                                                <td>
                                                    {produto.preco_go_fisica}
                                                </td>
                                                <td>
                                                    {produto.preco_rj_fisica}
                                                </td>
                                                <td>
                                                    {produto.preco_anapolis_fisica}
                                                </td>
                                                <td>
                                                    {produto.descricao}
                                                </td>
                                                <td>
                                                    {produto.descricao2}
                                                </td>
                                                <td>
                                                    {produto.peso}
                                                </td>
                                                <td>
                                                    {produto.status}
                                                </td>
                                                <td>
                                                    {produto.categoriaId}</td>
                                                <td>
                                                    {produto.tipoLoja_id}
                                                </td>
                                                <td>
                                                    {produto.frase_promocional}
                                                </td>
                                                <td>
                                                    {produto.unidade_de_medida_promocional}
                                                </td>
                                                <td>
                                                    {produto.alcoolico} 
                                                </td>
                                                <td>
                                                    {produto.createdAt} 
                                                </td>
                                                <td>
                                                    {produto.updatedAt}
                                                </td>
                                            </tr>)
                                            }
                                        )}
                                </tbody>
                        </table>
                    </TableContainer>
                </div>
            </div>
        </Container>
    )
}