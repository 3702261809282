import styled from 'styled-components';

export const ContainerNull = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    height: 250px;
    border: 1px solid black;
    background-color: #FFF;
    margin-bottom: 10px;
    padding: 8px;
`;

export const ContainerTabela = styled.div`
    margin: 15px;
    display: flex;
    justify-content: center;
    overflow-y: auto;
`;

export const Container = styled.div`
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 60dvh;
    overflow-y: auto;

    > .pesquisaContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin-top: 5px;
    }
`;

export const Tabela = styled.table`
&caption{
    font-size: 1.5em;
    margin: .5em 0 .75em;
}
`;

export const Thead = styled.thead`
    display: table;
    width: 100%;
    table-layout: fixed;
    position: sticky;
    top: 0;

    @media(max-width:700px){
        border: none;
        clip:rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
`;

export const TR = styled.tr`
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 20px;
    text-align: center;
    @media(max-width:700px){
        border-bottom: 3px solid #DDD;
        display: block;
        margin-bottom: .65em;
    }
`;

export const TH = styled.th`
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
    background-color: #DC4405;
    color: #FFF;
    padding:10px;
    text-align: center;
`;

export const Tbody = styled.tbody`
    display: block;
    width: 100%;
`;

export const TD = styled.td`
    @media(max-width:700px){
        border-bottom: 1px solid #DDD;
        display: block;
        font-size: .8em;
        text-align: right;

        &::before{
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
`;

export const SpanStatus = styled.span`
    padding: 3px;
    border-radius: 3px;
    color: #FFF;
`;