import styled from "styled-components";

export const ErrorProdutosCampanhaStyled = styled.div`
 display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);

.div-branca {
    position: relative;
    background-color: white;
    width: max-content;
    padding: 30px ;
    border-radius: 50px;
    width: 80%;
  }

  .header{
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  h2{
    margin-bottom: 7px;
    margin-left: 10px;
    font-size: 22px;
    color: #FA5252;
  }

  h3{
    margin-top: 20px;
    margin-left: 10px;
    font-size: 18px;
    display: flex;
  }

  h4{
    font-weight: 500;
    margin-left: 8px;
  }

  h5{
    font-size: 16px;
    margin-left: 20px;
    color: #FA5252;
  }

  .card{
    padding-bottom: 20px;
    border-bottom: #A7A7A7 solid 1px;
  }

  .close{
    position: absolute;
    right: -10px;
    top: -15px;
    padding: 10px 17px;
    border-radius: 100%;
    font-size: 30px;
    color: white;
    background-color: #FA5252;
  }

  .scroll{
    max-height: 500px;
    overflow-y: auto;
  }
`