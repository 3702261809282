import { Helmet } from "react-helmet"
import HeaderLateral from "../../components/HeaderLateral"
import AtualizacaoProgramadaComponente from "../../components/Dash/AtualizacaoProgramada"
import { Container } from "./style"

export const AtualizacaoProgramada = () => {
    return (
        <Container>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Campanhas</title>
            </Helmet>
            <HeaderLateral />
            <AtualizacaoProgramadaComponente/>
        </Container>
    )
}