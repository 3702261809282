import { UploadXlsxForm } from "./estilo";
import { BsUpload } from "react-icons/bs";
import * as XLSX from "xlsx/xlsx.mjs";

export default function VincularCampanhasProdutosImport({setDados}){
    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setDados(json);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const baixarExemplo = () => {
        XLSX.writeFile({
            SheetNames: ['Exemplo'],
            Sheets: {
                'Exemplo': XLSX.utils.json_to_sheet([
                    { 'id': '1' },
                    { 'codigo': '456' },
                    { 'codigo': '789', 'id': '3' },
                ])
            }
        }, 'exemplo.xlsx');        
    }

    return (
        <UploadXlsxForm>
            <div className="container">
                <h6>Importar Planilha XLSX</h6>
                <div className="input-container">
                    <label htmlFor="arquivo-xlsx">
                        <BsUpload />
                    </label>
                    <p>Escolher arquivo</p>
                    <input 
                        id="arquivo-xlsx"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={readUploadFile}
                    />
                </div>
                <div className="button-container">
                    <button type="button" onClick={baixarExemplo} >BAIXAR EXEMPLO</button>
                </div>
            </div>
        </UploadXlsxForm>
    )
}