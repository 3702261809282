import * as yup from "yup";
import { createContext } from "react";
import {yupResolver} from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";

export const UploadXlsxContext = createContext({});

export default function UploadXlsxProvider({children}){
    yup.addMethod(yup.mixed, "xlsxFormat", function (errorMessage) {
        return this.test(`xlsx-format`, errorMessage, function (value) {
            if(value[0]?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                return true
            };
            
            const { path, createError } = this;
        
            return createError({ path, message: errorMessage })
        });
    });

    const schema = yup.object().shape({
        xlsxFile: yup.mixed().nullable().xlsxFormat("Formato de arquivo inválido.").required("Selecione um arquivo.")
    })

    const { register, handleSubmit, reset , formState: {errors} } = useForm({
        resolver: yupResolver(schema)
    })

    return(
        <UploadXlsxContext.Provider value={{register,handleSubmit,reset,errors}}>
            {children}
        </UploadXlsxContext.Provider>
    )
}