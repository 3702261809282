import * as XLSX from "xlsx/xlsx.mjs";

export const lerPlanilha = (callback) => (e) => {
    e.preventDefault();

    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            callback(json);
        };

        if(e.target.files[0]){
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }
}