import { useState } from "react";
import { UploadXlsxForm } from "./estilo";
import { BsUpload } from "react-icons/bs";
import { useAuth } from "../../contexts/auth";
import { toast } from "react-toastify";
import { lerPlanilha } from "../../utils/planilha/lerPlanilha";
import { baixarPlanilha } from "../../utils/planilha/baixarPlanilha";
import api from "../../services/api";
import { ErrorProdutosCampanha } from "../modals/ErrorsProdutosCampanha";

export default function UploadProdutoCampanha({
    campanha_id,
    getProdutosDaCampanha,
    produtos
}){
    const { user, signOut } = useAuth();

    const [inputFile, setInputFile] = useState(null)
    const [data,setData] = useState([])
    const [erros, setErrors] = useState()
    const [loading, setLoading] = useState(false)

    const enviarArquivo = async () => {
        setLoading(true)
        let arrayDeErros = []

        for(let produto of data){
            const validacaoProdutos = (
                produto.descricao?.length > 36 || 
                produto.descricao2?.length > 36 || 
                produto.precoSP && isNaN(Number(produto.precoSP)) ||
                produto.precoDF && isNaN(Number(produto.precoDF)) ||
                produto.precoGO && isNaN(Number(produto.precoGO)) ||
                produto.precoRJ && isNaN(Number(produto.precoRJ))
            ) 
            
            if(validacaoProdutos){
                arrayDeErros.push({
                    codigo: produto.codigo,
                    produto: produto.descricao,
                    erros: [
                        produto?.descricao.length > 36 ? "Título excedeu o limite de 36" : null, 
                        produto?.descricao2.length > 36 ? "Subtítulo excedeu o limite de 36" : null,
                        produto?.precoSP && isNaN(Number(produto.precoSP)) ? "PrecoGO não é um número" : null,
                        produto?.precoDF && isNaN(Number(produto.precoDF)) ? "PrecoGO não é um número" : null,
                        produto?.precoGO && isNaN(Number(produto.precoGO)) ? "PrecoGO não é um número" : null,
                        produto?.precoRJ && isNaN(Number(produto.precoRJ)) ? "PrecoGO não é um número" : null
                    ]
                })                
            }
        }
  
        if(arrayDeErros.length > 0){
            setLoading(false)
            return setErrors(arrayDeErros)
        }

        if (data){
            try {
                await api.patch(`/campanhas/${campanha_id}/produtos`,
                    {
                        produtos: data,
                        usuario_id: user.id_usuario
                    },
                    {
                        headers: { 'Authorization': user.token },
                    },
                )
                setLoading(false)
                await getProdutosDaCampanha();
                toast.success('Produtos atualizados com sucesso')
            } catch (error) {
                setLoading(false)
                if(error.response.status === 401) signOut();
    
                if (error.response.data?.messages){
                    const erros = error.response.data.messages
                    erros.forEach((erro) => {
                        toast.error(erro)
                    })
                } else {
                    if(error.response.data?.error == "Produtos não encontrados!"){                        
                        error.response.data?.produtosNaoEncontrados.map((produto) => {
                        arrayDeErros.push({
                            codigo: produto.codigo,
                            produto: produto.descricao,
                            erros: [
                                "Código não encontrado entre seus produtos cadastrados. Cadastre o produto ou remova a linha da planilha."
                            ]
                        }) 
                        })

                        setErrors(arrayDeErros)
                    }

                    toast.error(error.response.data?.error || 'Erro ao atualizar produtos')
                }
            }
        }
    }

    const planilhaExemplo = () => {
        baixarPlanilha({
            nomeDoArquivo: 'exemplo_produtos_campanha.xlsx',
            abas: [{
                nome: 'produtos da campanha',
                dados: [{
                    id: '1',
                    codigo: '123456',
                    titulo: 'titulo de exemplo',
                    subtitulo: 'subtitulo de exemplo',
                    frase_promocional: 'frase promocional de exemplo',
                    unidade_de_medida_promocional: 'unidade de medida de exemplo',
                    precoSP: '10.00',
                    precoRJ: '15.00',
                    precoDF: '20.00',
                    precoGO: '25.00',
                }],
            }],
        })
    }

    const baixarRelatorio = () => {
        baixarPlanilha({
            nomeDoArquivo: 'relatorio_produtos_campanha.xlsx',
            abas: [{
                nome: 'produtos da campanha',
                dados: produtos.map((produto) => ({
                    id: produto.id,
                    codigo: produto.codigo,
                    produto: produto.produto,
                    categoria: produto.categoria,
                    titulo: produto.descricao,
                    subtitulo: produto.descricao2,
                    frase_promocional: produto.frase_promocional,
                    unidade_de_medida_promocional: produto.unidade_de_medida_promocional,
                    status: produto.status,
                    precoSP: produto.precoSP,
                    precoRJ: produto.precoRJ,
                    precoDF: produto.precoDF,
                    precoGO: produto.precoGO,
                })),
            }],
        })
    }

    const baixarProdutos = async () => {
        try {
            const resposta = await api.get('/produtos',
                {
                    headers:{
                        'Authorization':user.token
                    }
                }
            )

            baixarPlanilha({
                nomeDoArquivo: 'produtos.xlsx',
                abas: [{
                    nome: 'produtos',
                    dados: resposta.data.produtos.filter(produto => produto.status === 'ATIVO').map((produto) => ({
                        id: produto.id,
                        codigo: produto.codigo,
                        produto: produto.produto,
                        categoria: produto.categoria,
                        titulo: produto.descricao,
                        subtitulo: produto.descricao2,
                        frase_promocional: produto.frase_promocional,
                        unidade_de_medida_promocional: produto.unidade_de_medida_promocional,
                        precoSP: produto.precoSP,
                        precoRJ: produto.precoRJ,
                        precoDF: produto.precoDF,
                        precoGO: produto.precoGO,
                    })),
                }],
            })
        }
        catch (error) {
            if(error.response.status === 401) signOut();
    
            toast.error(error.response.data?.error || 'Erro ao buscar produtos')
        }
    }

    const formatarPlanilha = (json = []) => {
        const produtosFormatados = json.map((produto) => {
            const resultado = {}

            for (let chave in produto){
                const valor = String(produto[chave])

                if (chave === 'titulo') chave = 'descricao'
                if (chave === 'subtitulo') chave = 'descricao2'

                resultado[chave] = valor.trim()
            }

            return resultado
        })

        setData(produtosFormatados)
    }

    if(loading){
        return(
            <div className='modalPrecificador'>
                <div className='modalPrecificador-container'>
                    <h1>Carregando...</h1>
                </div>
            </div>
        )
    }

    return (
        <UploadXlsxForm>
            <div className="container">
                <h6 title="Import para atualizar os produtos">IMPORTAR PARA CRIAR OU ATUALIZAR OS PRODUTOS</h6>
                <div className="input-container">
                    <label htmlFor="arquivo-xlsx">
                        <BsUpload />
                    </label>
                    <p>{inputFile ? inputFile : 'Escolher arquivo'}</p>
                    <input 
                        id="arquivo-xlsx"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={(e) => {
                            if (e.target.files) setInputFile(e.target.files[0].name)
                            lerPlanilha(formatarPlanilha)(e)
                        }}
                        onClick={(e) => {
                            e.target.value = null
                            setInputFile(null)
                            setData([])
                        }}
                        placeholder="Selecione um arquivo"
                    />
                </div>
                <div className="button-container">
                    <button
                        type='button' 
                        className="exemplo"
                        onClick={planilhaExemplo}
                    >MODELO PARA CADASTRO</button>
                    <button
                        type='button' 
                        title="Atualizar produtos da campanha"
                        className="enviar"
                        onClick={enviarArquivo}
                        disabled={!data.length}
                    >ENVIAR</button>
                </div>
                <h6 title="Relatórios">EXTRAÇÃO DE RELATÓRIOS</h6>
                <div className="button-container">
                    <button
                        type='button' 
                        className="relatorio"
                        onClick={baixarRelatorio}
                        disabled={!produtos.length}
                    >PRODUTOS CAMPANHA</button>
                    <button
                        type='button' 
                        className="relatorio"
                        onClick={baixarProdutos}
                    >PRODUTOS CADASTRADOS</button>
                </div>
            </div>
            {erros && <ErrorProdutosCampanha erros={erros} setErros={setErrors}/>}
        </UploadXlsxForm>
    )
}