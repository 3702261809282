import HeaderLateral from "../../components/HeaderLateral";
import SelecionarPrecificador from "../../components/SelecionarPrecificador";
import './solicitarpedidos.css';
import api from '../../services/api';
import {IoClipboardOutline} from 'react-icons/io5';
import { useState ,useContext, useEffect} from "react";
import { AuthContext } from "../../contexts/auth";
import { useLoja } from "../../contexts/loja";
import { toast } from "react-toastify";
import {Helmet} from 'react-helmet';
import { SolicitarPedidosContext } from "../../contexts/solicitarPedidos";
import A3Horizontal from '../../files/A3-HORIZONTAL.pdf';
import A3Vertical from '../../files/A3-VERTICAL.pdf';

export default function SolicitarPedidos(){
    const {user,signOut} = useContext(AuthContext);
    const [cpf,setCpf] = useState('');
    const [obs,setObs] = useState('');
    const [formatoSelecionado,setFormatoSelecionado] = useState(0);
    const [campanhas,setCampanhas] = useState([]);
    const [campanhaSelecionada,setCampanhaSelecionada] = useState({});
    const [pedido,setPedido] = useState({});
    const [listaProd] = useState([
        {id:1,nome:'precificador',valor:'precificador'},
    ])
    const {cpfMask,register,handleSubmit,errors, reset} = useContext(SolicitarPedidosContext);
    const [abriModal,setAbriModal] = useState(false);

    const { loja } = useLoja();
    
    const formatos = [
        { name: 'A4 Swift em dobro', id: 6, remover: !(loja.varejo === 'Fisica' || ["BARBOSA","LOPES"].includes(loja.varejo.toUpperCase()))  },
        { name: 'Etiqueta Swift em dobro', id: 7, remover: !(loja.varejo === 'Fisica' || ["BARBOSA","LOPES"].includes(loja.varejo.toUpperCase()))  },
        { name: 'Cavalete Swift em dobro', id: 8, remover: !(loja.varejo === 'Fisica' || ["BARBOSA","LOPES"].includes(loja.varejo.toUpperCase()))  }
    ].filter((formato) => !formato.remover)
    
    const formatosSemCampanha = [
        { name: 'A4', id: 1 },
        { name: 'A3 Pedestal', id: 2, remover: loja.varejo === 'Fisica' },
        { name: 'A3 Cavalete', id: 3 },
        { name: 'Oportunidade A4', id: 4, remover: !(loja.varejo === 'Fisica' || ["BARBOSA","LOPES"].includes(loja.varejo.toUpperCase())) },
        { name: 'Oportunidade Etiquetas', id: 5, remover: !(loja.varejo === 'Fisica' || ["BARBOSA","LOPES"].includes(loja.varejo.toUpperCase())) },
    ].filter((formato) => !formato.remover)
    
    function adicionarItens(){
        if(pedido.id !== ''){
            setAbriModal(!abriModal)
        }else{
            toast.info('Nenhum pedido em andamento')
        }
    }

    //CRIA PEDIDO
    function criarPedido(data, e){
        const dadosProduto = {
            produto:data.produto,
            cpf:data.cpf,
            dataEntrega:"",
            tipoEntrega:loja.tipoEntrega,
            observacao:data.observacao,
            loja_id:loja.id,
            fornecedor_id:loja.fornecedorId,
            formato_id:data.formatoId,
            campanhaId:Number(data.campanhaId),
            usuario_id:user.id_usuario
        }
        api.post('/pedidos/pedidosweb',
            dadosProduto,
            {
                headers:{'Authorization':user.token}
            }
        ).then((resp)=>{
            getPedido(resp.data.pedidoId)
            setAbriModal(!abriModal)
            toast.success('Adicione os itens ao pedido.')
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
            }
        })
    }

    function buscarCampanhas(){
        if(loja.varejo === 'Fisica'){
            api.get(`/campanhas?opcoesPedido&preco=${loja.preco}&loja=${loja.varejo}`,
                {
                    headers:{
                        'Authorization':user.token
                    }
                }
            )
            .then((resp)=>{
                setCampanhas(resp.data.campanhas)
            })
            .catch((error)=>{})
        }
    }

    const getPedido = (id) => {
        api.get(`/pedidos/${id}`,
            {
                headers:{'Authorization':user.token}
            }
        ).then((resp)=>{
            setPedido(resp.data.pedido[0])
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
            }
        })
    }
    
    useEffect(() => {
        buscarCampanhas()
        reset({formatoId: 0})
    }, [])

    return(
        <div className="solicitarcontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Solicitar Pedido</title>
            </Helmet>
            <HeaderLateral/>
            <div className="solicitar-interno">
                <div className="solicitar-areacomponentes">
                    <div className="solicitar-header">
                        <IoClipboardOutline size={34}/>
                        <h3>Solicitar Pedido</h3>
                    </div>
                    <div className="pedido-cadastro">
                        <div className="form-container">
                            <form className="solicitar-pedidos-form" onSubmit={handleSubmit(criarPedido)}>
                                <div className="input-container">
                                    <h3>Produto*</h3>
                                    <select id='selProd' {...register("produto")}>
                                            <option value='' >Selecionar</option>
                                            {listaProd.map((produto)=>(
                                                <option key={produto.id} value={produto.valor}>{produto.nome}</option>
                                                ))}
                                    </select>
                                    {errors.produto && <p style={{ color:"red"}}>{errors.produto.message}</p>}
                                </div>

                                <div className="input-container">
                                    <h3>Identificação*</h3>
                                    <input  type="text" placeholder='Inserir cpf' value={cpf} {...register("cpf", {onChange: (e) => setCpf(cpfMask((e.target.value)))})}/>
                                    {errors.cpf && <p style={{ color:"red"}}>{errors.cpf?.message}</p>}
                                </div>

                                {(campanhas.length > 0) && (
                                    <div className="input-container">
                                        <h3>Campanha</h3>
                                        <select
                                            id='selCampanha'
                                            {...register("campanhaId", {
                                                onChange: (e) => {
                                                    setCampanhaSelecionada(campanhas.find((campanha) => campanha.id == e.target.value))
                                                    reset({formatoId: 0})
                                                }
                                            })}
                                        >
                                            <option key={0} value={0}>Selecionar</option>
                                            {campanhas.map((campanha)=> (
                                                <option key={campanha.id + "_campanha"} value={campanha.id}>{campanha.nome}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                <div className="input-container">
                                    <h3>Formato*</h3>
                                    <select
                                        id='selForm'
                                        {...register("formatoId", {
                                            onChange: (e) => setFormatoSelecionado(e.target.value)
                                        })}
                                    >
                                        <option key={0} value={0}>Selecionar</option>
                                        {
                                        campanhaSelecionada && campanhaSelecionada.id ?
                                        formatos
                                        .filter((formato) => !campanhaSelecionada?.id || campanhaSelecionada?.formatosId?.split(',')?.includes(String(formato.id)))
                                        .map((formato)=>(
                                            <option key={formato.id + "_formatos"} value={formato.id}>{formato.name}</option>
                                        )) :
                                            formatosSemCampanha.map((formato)=>(
                                                <option key={formato.id + "_formatos"} value={formato.id}>{formato.name}</option>
                                            ))

                                        }
                                    </select>
                                    {errors.formatoId && <p style={{ color:"red"}}>{errors.formatoId.message}</p>}
                                    {formatoSelecionado == 2 && <a href = {A3Vertical} target = "_blank">Baixar modelo</a>}
                                    {formatoSelecionado == 3 && <a href = {A3Horizontal} target = "_blank">Baixar modelo</a>}
                                </div>
                                <div className="input-container">
                                    <h3>Observação</h3>
                                    <input type="text" placeholder="Observação" value={obs} {...register("observacao", {onChange: (e)=> setObs(e.target.value)})}/>
                                    {errors.observacao && <p style={{ color:"red"}}>{errors.observacao.message}</p>}
                                </div>
                                <div className="button-container">
                                    <button className="actionSolicitar" type="submit">
                                        SOLICITAR
                                    </button>
                                    <button type="button" className="actionSolicitar" onClick={adicionarItens}>
                                        (+) ITENS
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {(abriModal && !!pedido?.id) && (
                <SelecionarPrecificador
                    pedido={pedido}
                    formato={formatoSelecionado}
                    campanha={campanhaSelecionada}
                    fechar={()=>setAbriModal(!abriModal)}
                />
            )}
        </div>
    )
}