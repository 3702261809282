import { useEffect, useState, useContext } from 'react';
import {
    ContainerNull,
    Container,
    ContainerProdutos,
    Titulo,
    AreaHeader,
    AreaPesquisa,
    ButtonNovo,
    ContainerTabela,
    Tabela,
    Thead,
    TR,
    TH,
    Tbody,
    TD,
    ButtonEditar
} from './estilo';
import { AuthContext } from '../../../contexts/auth';
import api from '../../../services/api';
import InputPesquisa from '../../InputPesquisa';
import {IoAlertCircleOutline} from 'react-icons/io5';
import {AtualizacaoProgramadaModal} from "../../modals/AtualizacaoProgramada";
import { ListaAtualizacaoProgramada } from '../../ListaAtualizacaoProgramada';

export default function AtualizacaoProgramadaComponente(){
    const {user} = useContext(AuthContext);
    const [loading,setLoading] = useState(true);
    const [planilhas , setPlanilhas] = useState([]);
    const [planilhasFiltradas, setPlanilhasFiltradas] = useState([]);
    const [planilhaSelecionada, setPlanilhaSelecionada] = useState();
    const [modal,setModal] = useState(false);

    const buscarAtualizacoes = () => {
        api.get('/produtos/jsonProdutosAtualizar',
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setLoading(false)
            setPlanilhas(resp.data)
            setPlanilhasFiltradas(resp.data)
        })
        .catch((error)=>{
            setLoading(false)
            console.log(error)
        })
    };
    
    useEffect(() => {
        buscarAtualizacoes()
    }, [planilhaSelecionada]) 

    function filtrar(lista, valor) {
        return lista.filter((planilha) => {
            if(!planilha) return null;
            
            return(
                planilha.nome.toUpperCase().includes(valor.toUpperCase()) ||
                String(planilha.id).toUpperCase().includes(valor)
            )}
    );
    }

    function buscarAtualizacao(dados){
        api.get(`/produtos/atualizacao/programada/${dados.nome}/${dados.data}`,
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setPlanilhaSelecionada(resp.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    if (loading) return (
        <Container>
            <ContainerProdutos>
                <Titulo>Carregando...</Titulo>
            </ContainerProdutos>
        </Container>
    )

    return(
        <Container>
            <ContainerProdutos>
                <Titulo>Atualização Programada</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa
                            lista={planilhas}
                            setLista={setPlanilhasFiltradas}
                            funcaoDeFiltro={filtrar}
                        />
                    </AreaPesquisa>

                    <button className='botao' onClick={() => setModal(true)}>
                        Novo
                    </button>
                </AreaHeader>

                {planilhasFiltradas.length === 0 ? (
                    <ContainerNull>
                        <IoAlertCircleOutline size={50} color='#FF0000'/>
                        <h1>Nenhuma campanha encontrada...</h1>
                    </ContainerNull>
                ) : (
                    <ContainerTabela>
                        <Tabela>
                            <Thead>
                                <TR>
                                    <TH scope='col'>Nome</TH>
                                    <TH scope='col'>Data Atualizacao Programada</TH>
                                    <TH scope='col'>Detalhes</TH>
                                </TR>
                            </Thead>
                                <Tbody>
                                    {planilhasFiltradas.map((campanha)=> { 
                                        if(!campanha) return null;

                                        const data = campanha.data?.split('T')
                                        const dataFormatada = data ? data[0]?.split('-').reverse().join('/') : ""
                                        const hora = data ? data[1].split(':') : ""
                                        const horaFormatada = hora ? `${hora[0]}:${hora[1]}` : ""

                                        return(
                                        <TR key={campanha.id}>
                                            <TD data-label='campanha'>{campanha.nome}</TD>
                                            <TD data-label='data de inicio'>{dataFormatada} {horaFormatada}</TD>
                                            <TD data-label='#'>
                                                <ButtonEditar onClick={() => buscarAtualizacao(campanha)}>
                                                    ver mais
                                                </ButtonEditar>
                                            </TD>
                                        </TR>
                                    )})}
                                </Tbody>
                        </Tabela>
                    </ContainerTabela>
                )}
            </ContainerProdutos>
            {modal && <AtualizacaoProgramadaModal setModal={setModal} />}
            {planilhaSelecionada && <ListaAtualizacaoProgramada planilha={planilhaSelecionada} setPlanilha={setPlanilhaSelecionada}/>}
        </Container>
    )
}