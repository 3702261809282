import { useState,useContext,useEffect } from 'react';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,ContainerPreco,TituloArea,AreaItem,SubTitulo,Select,Input,Button,AreaButton} from './estilo.js';
import {AuthContext} from '../../../contexts/auth';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { IoChevronBackOutline } from "react-icons/io5";
import ErrosTable from '../../ErrorsTable';
import ErrorsTr from '../../ErrorsTr';
import UploadXlsx from '../../UploadXlsx/index.js';
import editarProdutosXlsx from "../../../planilhasDeExemplo/editar_produtos.xlsx";
import { segmentos } from '../../../utils/tiposDeSegmentoDeLoja';

export default function AlteraProduto({conteudo,fechar}){
    const [codigo, setCodigo] = useState(conteudo.codigo)
    const [produto,setProduto] = useState(conteudo.produto)
    const [descricao,setDescricao] = useState(conteudo.descricao)
    const [descricao2,setDescricao2] = useState(conteudo.descricao2)
    const [frasePromocional,setFrasePromocional] = useState(conteudo.frase_promocional)
    const [unidadeDeMedidaPromocional, setUnidadeDeMedidaPromocional] = useState(conteudo.unidade_de_medida_promocional)
    const [alcoolico,setAlcoolico] = useState(conteudo.alcoolico == 1 ? true : false)
    const [peso,setPeso] = useState(conteudo.peso)
    const [participaPromocaoCombo,setParticipaPromocaoCombo] = useState(conteudo.participaPromocaoCombo)
    const [categorias,setCategorias] = useState([conteudo.categoria])
    const [categoriaSelecionada,setCategoriaSelecionada] = useState(conteudo.categoriaId)
    const [tipoLoja_id,setTipoLoja_id] = useState(conteudo.segmentoLoja)
    const [precoB,setPrecoB] = useState(conteudo.precoSP_barbosa)
    const [precoAC,setPrecoAC] = useState(conteudo.precoAC)
    const [precoAL,setPrecoAL] = useState(conteudo.precoAL)
    const [precoAP,setPrecoAP] = useState(conteudo.precoAP)
    const [precoAM,setPrecoAM] = useState(conteudo.precoAM)
    const [precoBA,setPrecoBA] = useState(conteudo.precoBA)
    const [precoCE,setPrecoCE] = useState(conteudo.precoCE)
    const [precoDF,setPrecoDF] = useState(conteudo.precoDF)
    const [precoES,setPrecoES] = useState(conteudo.precoES)
    const [precoGO,setPrecoGO] = useState(conteudo.precoGO)
    const [precoMA,setPrecoMA] = useState(conteudo.precoMA)
    const [precoMT,setPrecoMT] = useState(conteudo.precoMT)
    const [precoMS,setPrecoMS] = useState(conteudo.precoMS)
    const [precoMG,setPrecoMG] = useState(conteudo.precoMG)
    const [precoPA,setPrecoPA] = useState(conteudo.precoPA)
    const [precoPB,setPrecoPB] = useState(conteudo.precoPB)
    const [precoPR,setPrecoPR] = useState(conteudo.precoPR)
    const [precoPE,setPrecoPE] = useState(conteudo.precoPE)
    const [precoPI,setPrecoPI] = useState(conteudo.precoPI)
    const [precoRN,setPrecoRN] = useState(conteudo.precoRN)
    const [precoRS,setPrecoRS] = useState(conteudo.precoRS)
    const [precoRO,setPrecoRO] = useState(conteudo.precoRO)
    const [precoRR,setPrecoRR] = useState(conteudo.precoRR)
    const [precoSC,setPrecoSC] = useState(conteudo.precoSC)
    const [precoSP,setPrecoSP] = useState(conteudo.precoSP)
    const [precoSE,setPrecoSE] = useState(conteudo.precoSE)
    const [precoTO,setPrecoTO] = useState(conteudo.precoTO)
    const [precoRJ,setPrecoRJ] = useState(conteudo.precoRJ)
    const [preco_sp_guanabara, setPreco_sp_guanabara] = useState(conteudo.preco_sp_guanabara)
    const [precoSP_fisica,setPrecoSP_fisica] = useState(conteudo.preco_sp_fisica)
    const [precoDF_fisica,setPrecoDF_fisica] = useState(conteudo.preco_df_fisica)
    const [precoDF2_fisica,setPrecoDF2_fisica] = useState(conteudo.preco_df2_fisica)
    const [precoGO_fisica,setPrecoGO_fisica] = useState(conteudo.preco_go_fisica)
    const [precoRJ_fisica,setPrecoRJ_fisica] = useState(conteudo.preco_rj_fisica)
    const [precoAnapolis_fisica,setPrecoAnapolis_fisica] = useState(conteudo.preco_anapolis_fisica)
    const [status, setStatus] = useState(conteudo.status)
    const [errorsMsg,setErrorsMsg] = useState('');

    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        //BUSCA FORNECEDOR
        function buscaCategorias(){
            setLoading(true)
            api.get('/categorias',
            {
                headers:{
                    'Authorization':user.token
                }
            })
            .then((resp)=>{
                setCategorias(resp.data.categorias)
                setLoading(false)
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoading(false)
                }
            })
        }
        buscaCategorias()
    },[user.token,signOut])

    //EDITA PRODUTOS
    function editaProdutos(){
        setLoading(true)
        api.patch('/produtos/atualiza',
            {
                codigo:codigo,
                produto:produto,
                descricao:descricao,
                descricao2:descricao2,
                peso:peso,
                categoria_id:categoriaSelecionada,
                frase_promocional: frasePromocional,
                unidade_de_medida_promocional: unidadeDeMedidaPromocional,
                alcoolico: alcoolico ? 1 : 0,
                tipoLoja_id:tipoLoja_id,
                status:status,
                precoSP_barbosa:precoB,
                precoAC:precoAC,
                precoAL:precoAL,
                precoAP:precoAP,
                precoAM:precoAM,
                precoBA:precoBA,
                precoCE:precoCE,
                precoDF:precoDF,
                precoES:precoES,
                precoGO:precoGO,
                precoMA:precoMA,
                precoMT:precoMT,
                precoMS:precoMS,
                precoMG:precoMG,
                precoPA:precoPA,
                precoPB:precoPB,
                precoPR:precoPR,
                precoPE:precoPE,
                precoPI:precoPI,
                precoRN:precoRN,
                precoRS:precoRS,
                precoRO:precoRO,
                precoRR:precoRR,
                precoSC:precoSC,
                precoSP:precoSP,
                precoSE:precoSE,
                precoTO:precoTO,
                precoRJ:precoRJ,
                preco_sp_guanabara:preco_sp_guanabara,
                preco_sp_fisica:precoSP_fisica,
                preco_df_fisica:precoDF_fisica,
                preco_df2_fisica:precoDF2_fisica,
                preco_go_fisica:precoGO_fisica,
                preco_rj_fisica:precoRJ_fisica,
                preco_anapolis_fisica:precoAnapolis_fisica
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
        ).then((resp)=>{
            setLoading(false)
            window.location.reload()
            toast.success(resp.data.mensagem)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }
    function editarProdutos(results){
        const produtos = results.data;
        api.patch('/produtos/atualizacoes', {produtos}, {
            headers:{
                'Authorization': user.token
            }
        }).then((resp) => {
            window.location.reload()
            toast.success(resp.data.mensagem)
        }).catch((error) => {
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            } else if (error.response.status === 406) {
                toast.info(error.response.data.error)
            } else if (error.response.status === 413){
                toast.info('Quantidade de dados muito grande.');
            } else {
                toast.info("Erro ao editar produtos.");
                setErrorsMsg(error.response.data.errors);
            }
        })
        
    }
    return(
        <Modal>
            {errorsMsg ? (
                <ContainerModal>
                    <h1 className='errors-title'>Lista de erros</h1>
                    <div className='errors-table-container'>
                        <ErrosTable titleColumns={['mensagem','item','valor']}>
                            {errorsMsg.map((erro) => {
                                return <ErrorsTr erro={erro}/>
                            })}
                        </ErrosTable>
                    </div>
                    <button className='errors-button' onClick={(e) => setErrorsMsg('')}>
                        <IoChevronBackOutline size={20}/>
                        VOLTAR
                    </button>
                </ContainerModal>
            ) : (
                <ContainerModal>
                    <Titulo>EDITAR PRODUTO</Titulo>
                    <div className='upload-container'>
                        <UploadXlsx resultCallback={editarProdutos} planilhaDeExemplo={editarProdutosXlsx}/>
                    </div>
                    <ContainerConteudo>
                        <TituloArea>DADOS PRODUTO</TituloArea>
                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>CODIGO</SubTitulo>
                                <Input type='number' value={codigo || ''} onChange={(e)=>setCodigo(e.target.value)} disabled={true}></Input>
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>PRODUTO</SubTitulo>
                                <Input type='text' value={produto || ''} onChange={(e)=> setProduto(e.target.value)}></Input>
                            </AreaItem>
                            <AreaItem>
                                    <SubTitulo>DESCRICAO</SubTitulo>
                                    <Input type='text' value={descricao || ''} onChange={(e)=> setDescricao(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>DESCRICAO 2</SubTitulo>
                                    <Input type='text' value={descricao2 || ''} onChange={(e)=> setDescricao2(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PESO</SubTitulo>
                                    <Input type='text' value={peso || ''} onChange={(e)=>setPeso(e.target.value)}></Input>
                                </AreaItem>
                        </ContainerItem>
                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>SEGMENTO DE LOJA</SubTitulo>
                                    <Select value={tipoLoja_id} onChange={(e)=>setTipoLoja_id(e.target.value)}>
                                        <option key={0} value={0}>SELECIONAR</option>
                                        {segmentos.map((segmento)=> (
                                            <option key={segmento.id + "_segmentos"} value={segmento.id}>{segmento.id} - {segmento.nome}</option>
                                        ))}
                                    </Select>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>CATEGORIA</SubTitulo>
                                    <Select value={categoriaSelecionada || ''} onChange={(e)=>{setCategoriaSelecionada(e.target.value)}}>
                                        {categorias.map((categoria,index)=>{
                                            return <option key={index} value={categoria.id}>{categoria.categoria}</option>
                                        })
                                        }
                                    </Select>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>STATUS</SubTitulo>
                                    <Select value={status} onChange={(e)=>{setStatus(e.target.value)}}>
                                        <option key={1} value='ATIVO'>ATIVO</option>
                                        <option key={2} value='DESATIVADO'>DESATIVADO</option>
                                    </Select>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>Frase promocional</SubTitulo>
                                    <Input type='text' value={frasePromocional} onChange={(e)=>setFrasePromocional(e.target.value.toUpperCase())} ></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>Un. de Medida Promocional</SubTitulo>
                                    <Input type='text' value={unidadeDeMedidaPromocional} onChange={(e)=>setUnidadeDeMedidaPromocional(e.target.value)} ></Input>
                                </AreaItem>
                            </ContainerItem>
                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>ALCOÓLICO</SubTitulo>
                                <div className='flex width100'>
                                    <div className='column'>
                                        <h4>SIM</h4>
                                        <input type='radio' checked={alcoolico} onClick={() => setAlcoolico(true)}/>
                                    </div>
                                    <div className='column'>
                                        <h4>NÃO</h4>
                                        <input type='radio' checked={!alcoolico} onClick={() => setAlcoolico(false)}/>
                                    </div>
                                </div>
                                </AreaItem>
                            </ContainerItem>
                            <TituloArea>PREÇOS DE VAREJO</TituloArea>
                            <ContainerPreco>
                                <AreaItem>
                                    <SubTitulo>PRECO BARBOSA</SubTitulo>
                                    <Input type='number' value={precoB || ''} onChange={(e)=>setPrecoB(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO AC</SubTitulo>
                                    <Input type='number' value={precoAC || ''} onChange={(e)=>setPrecoAC(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO AL</SubTitulo>
                                    <Input type='number' value={precoAL || ''} onChange={(e)=>setPrecoAL(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO AP</SubTitulo>
                                    <Input type='number' value={precoAP || ''} onChange={(e)=>setPrecoAP(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO AM</SubTitulo>
                                    <Input type='number' value={precoAM || ''} onChange={(e)=>setPrecoAM(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO BA</SubTitulo>
                                    <Input type='number' value={precoBA || ''} onChange={(e)=>setPrecoBA(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO CE</SubTitulo>
                                    <Input type='number' value={precoCE || ''} onChange={(e)=>setPrecoCE(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO DF</SubTitulo>
                                    <Input type='number' value={precoDF || ''} onChange={(e)=>setPrecoDF(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO ES</SubTitulo>
                                    <Input type='number' value={precoES || ''} onChange={(e)=>setPrecoES(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO GO</SubTitulo>
                                    <Input type='number' value={precoGO || ''} onChange={(e)=>setPrecoGO(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO MA</SubTitulo>
                                    <Input type='number' value={precoMA || ''} onChange={(e)=>setPrecoMA(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO MT</SubTitulo>
                                    <Input type='number' value={precoMT || ''} onChange={(e)=>setPrecoMT(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO MS</SubTitulo>
                                    <Input type='number' value={precoMS || ''} onChange={(e)=>setPrecoMS(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO MG</SubTitulo>
                                    <Input type='number' value={precoMG || ''} onChange={(e)=>setPrecoMG(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PA</SubTitulo>
                                    <Input type='number' value={precoPA || ''} onChange={(e)=>setPrecoPA(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PB</SubTitulo>
                                    <Input type='number' value={precoPB || ''} onChange={(e)=>setPrecoPB(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PR</SubTitulo>
                                    <Input type='number' value={precoPR || ''} onChange={(e)=>setPrecoPR(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PE</SubTitulo>
                                    <Input type='number' value={precoPE || ''} onChange={(e)=>setPrecoPE(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PI</SubTitulo>
                                    <Input type='number' value={precoPI || ''} onChange={(e)=>setPrecoPI(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RN</SubTitulo>
                                    <Input type='number' value={precoRN || ''} onChange={(e)=>setPrecoRN(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RS</SubTitulo>
                                    <Input type='number' value={precoRS || ''} onChange={(e)=>setPrecoRS(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RO</SubTitulo>
                                    <Input type='number' value={precoRO || ''} onChange={(e)=>setPrecoRO(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RR</SubTitulo>
                                    <Input type='number' value={precoRR || ''} onChange={(e)=>setPrecoRR(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO SC</SubTitulo>
                                    <Input type='number' value={precoSC || ''} onChange={(e)=>setPrecoSC(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO SP</SubTitulo>
                                    <Input type='number' value={precoSP || ''} onChange={(e)=>setPrecoSP(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO SE</SubTitulo>
                                    <Input type='number' value={precoSE || ''} onChange={(e)=>setPrecoSE(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO TO</SubTitulo>
                                    <Input type='number' value={precoTO || ''} onChange={(e)=>setPrecoTO(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RJ</SubTitulo>
                                    <Input type='number' value={precoRJ || ''} onChange={(e)=>setPrecoRJ(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO GUANABARA</SubTitulo>
                                    <Input type='number' value={preco_sp_guanabara || ''} onChange={(e)=>setPreco_sp_guanabara(e.target.value)}></Input>
                                </AreaItem>
                            </ContainerPreco>
                            <TituloArea>PREÇOS DE LOJA FISICA</TituloArea>
                            <ContainerPreco>
                                <AreaItem>
                                    <SubTitulo>PRECO_SP_FISICA</SubTitulo>
                                    <Input type='number' value={precoSP_fisica || 0} onChange={(e)=>setPrecoSP_fisica(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO_DF_FISICA</SubTitulo>
                                    <Input type='number' value={precoDF_fisica || 0} onChange={(e)=>setPrecoDF_fisica(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO_DF2_FISICA</SubTitulo>
                                    <Input type='number' value={precoDF2_fisica || 0} onChange={(e)=>setPrecoDF2_fisica(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO_GO_FISICA</SubTitulo>
                                    <Input type='number' value={precoGO_fisica || 0} onChange={(e)=>setPrecoGO_fisica(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO_RJ_FISICA</SubTitulo>
                                    <Input type='number' value={precoRJ_fisica} onChange={(e)=>setPrecoRJ_fisica(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO_ANAPOLIS_FISICA</SubTitulo>
                                    <Input type='number' value={precoAnapolis_fisica || ''} onChange={(e)=>setPrecoAnapolis_fisica(e.target.value)}></Input>
                                </AreaItem>
                            </ContainerPreco>
                                <AreaButton>
                                        <Button onClick={fechar}>VOLTAR</Button>
                                        <Button onClick={editaProdutos}>SALVAR</Button>
                                </AreaButton>
                    </ContainerConteudo>
                </ContainerModal>
            )}
        </Modal>
    )
}