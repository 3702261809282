import { useState,useEffect} from 'react';
import {useAuth} from '../../../contexts/auth';
import api from '../../../services/api';
import {toast} from 'react-toastify';
import InputPesquisa from '../../InputPesquisa';
import {ContainerNull,ContainerTabela,Tabela,Thead,TR,TH,Tbody, Container, ModalContainer} from './estilo';

import { IoClose } from 'react-icons/io5';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { CampanhaProdutoTr } from '../../Tr/CampanhaProdutoTr';
import UploadProdutoCampanha from '../../UploadProdutoCampanha';

export default function CampanhaProdutos({ id, fechar, vincularProdutos }){
    const [produtos,setProdutos] = useState([])
    const [produtosFiltrados,setProdutosFiltrados] = useState([]);
    
    const [load,setLoad] = useState(false);
    const { user, signOut } = useAuth();

    const getProdutosDaCampanha = () => {
        setLoad(true)
        
        api.get(`/campanhas/${id}/produtos`,
            {
                headers:{'Authorization':user.token},
            },
        ).then((resp) => {
            const lista = resp.data.produtos.filter((produto) => produto.status === 'ATIVO')

            setProdutos(lista)
            setProdutosFiltrados(lista)
            setLoad(false)
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
            }
        })
    }

    const removerProduto = (produtoId) => {
        api.delete(`/campanhas/${id}/produtos/${produtoId}`,
            {
                headers:{'Authorization':user.token},
            },
        ).then(() => {
            setProdutos(e => e.filter((produto) => produto.id != produtoId))
            setProdutosFiltrados(e => e.filter((produto) => produto.id != produtoId))
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
            }
        })
    }

    useEffect(()=>{
        getProdutosDaCampanha()
    },[user.token,signOut])

    if (load === true) {
        return (
            <div className='modalPrecificador'>
                <div className='modalPrecificador-container'>
                    <h1>Carregando...</h1>
                </div>
            </div>
        )
    }

    function filtroDeProdutos(lista, valor){
        return lista.filter((produto) => (
            produto.produto.toUpperCase().includes(valor.toUpperCase()) ||
            String(produto.codigo ?? '').toUpperCase().includes(valor.toUpperCase()) || 
            produto.categoria.toUpperCase().includes(valor.toUpperCase())
        ));
    }


    const getProdutoPrecos = (produto_id) => async () => (
        await api.get(`/campanhas/${id}/produtos/${produto_id}`,
        {
            headers: { 'Authorization': user.token },
        },
    ))
    
    const atualizarProdutoPrecos = (produto_id) => async (data) => (
        await api.patch(`/campanhas/${id}/produtos/${produto_id}`,
        data,
        {
            headers: { 'Authorization': user.token },
        },
    ))

    return(
        <div className='modalPrecificador'>
            <div className='modalPrecificador-fechar'>
                <button
                    onClick={fechar}
                    className='modalPrecificador-btn-fechar'
                    type='button'
                >
                    <IoClose size={30} color='#FFF'/>
                </button>
            </div>
            <ModalContainer>
                <Container>
                    <h2>Produtos da campanha</h2>

                    <div className='pesquisaContainer'>
                        <div className='input-pesquisa-container'>
                            <InputPesquisa
                                lista={produtos}
                                setLista={setProdutosFiltrados}
                                funcaoDeFiltro={filtroDeProdutos}
                            />
                        </div>
                    </div>

                    <UploadProdutoCampanha
                        campanha_id={id}
                        getProdutosDaCampanha={getProdutosDaCampanha}
                        produtos={produtos}
                    />

                    <p className='explicacao'>Para ativar a campanha em uma região, é preciso cadastrar o preço de pelo menos um produto para essa região na tabela abaixo.</p>

                    {produtosFiltrados.length === 0 ? (
                        <ContainerNull>
                            <IoAlertCircleOutline size={50} color='#FF0000'/>
                            <h1>Nenhum produto encontrado...</h1>
                        </ContainerNull>
                    ) : (
                        <ContainerTabela>
                            <Tabela>
                                <Thead>
                                    <TR>
                                        <TH scope='col'>Id</TH>
                                        <TH scope='col'>Codigo</TH>
                                        <TH scope='col'>Produto</TH>
                                        <TH scope='col'>Categoria</TH>
                                        <TH scope='col'>Título</TH>
                                        <TH scope='col'>Subtítulo</TH>
                                        <TH scope='col'>Valição</TH>
                                        <TH scope='col'>Frase promocional</TH>
                                        <TH scope='col'>Unidade promocional</TH>
                                        <TH scope='col'>Segmento de loja</TH>
                                        <TH scope='col'>Status</TH>
                                        <TH scope='col'>Preço</TH>
                                        <TH scope='col'>#</TH>
                                    </TR>
                                </Thead>
                                <Tbody>
                                    {produtosFiltrados.map((produto)=> (
                                        <CampanhaProdutoTr
                                            getProdutoPrecos={getProdutoPrecos(produto.id)}
                                            atualizarProdutoPrecos={atualizarProdutoPrecos(produto.id)}
                                            produto={produto}
                                            removerProduto={removerProduto}
                                            key={produto.id}
                                        />
                                    ))}
                                </Tbody>
                            </Tabela>
                        </ContainerTabela>
                    )}
                </Container>
            </ModalContainer>
        </div>
    )
}