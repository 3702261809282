import styled from "styled-components";

export const SidebarContainer = styled.div`
    margin: 0;
    padding: 0;
    width: 220px;
    background-color: #DC4405;
    position: fixed;
    height: 100%;
    overflow-y: scroll;
    z-index: 9;

    .img-container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 100%;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
    }

    img{
        padding: 20px;
        height: 130px;
        width: 170px;     
    }

    span{
        font-weight: bold;
    }

    a{
        color: rgba(255, 255, 255, 0.7);
        font-size: 18px;
        padding: 16px;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: ease-in-out .4s;
        font-weight: bold;
    }

    a svg{
        margin-right: .5em;
    }

    a:hover{
        background-color: #FFF;
        color: #F28F1A;

        svg.icone path {
            fill: #F28F1A;
        }
    }

	.icone-triangulo{
		margin-left: auto;
		transform: rotate(${({menuAdmOpened}) => menuAdmOpened ? '90deg' : '0'});
	}

    > button{
        color: rgba(255, 255, 255, 0.7);
        width: 100%;
        font-weight:bold;
        font-size: 18px;
        padding: 16px;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: ease-in-out .4s;
        background-color: #DC4405;
        border: 0;
    }

    > button svg{
        margin-right: .5em;
    }

    > button:hover{
        background-color: #FFF;
        color: #F28F1A;
    }
    nav{
        display: flex;
        justify-content:center;

        svg {
            margin-right: 10px;
            width: 20px;
        }

        svg.icone path {
            fill: #FFF;
        }
    }

    .menu.active{
        display: flex;
    }

    .menu{
        display: none;
    }

    @media screen and (max-width:700px) {
        display: flex;
        flex-direction: column;
		width: 60%;
        max-width: 200px;
        height: 100vh;
        position: fixed;
        left: ${({menuMobile}) => menuMobile ? '0' : '-60%'};
        transition: left 0.3s linear 0s;
		z-index: 1;
		
        button > svg{
            margin: 0;
        }
        
        .img-container{
            display: none;
        }

        button {
            display: flex;
            gap: .5em;
            font-size: 16px;
            padding: 10px;
        }
        
        a{
            float: left;
            width: 100%;
            padding: 10px;
            font-size: 16px;
        }
        
        a:hover{
            background-color: #FFF;
            color: #F28F1A;
        }
    }
`

export const MenuMobile = styled.button`
    display: none;
    
    @media screen and (max-width: 700px){
        display: flex;   
        background-color: #DC4405;
        border: none;
        min-height: 50px;
        height: 50px;
        align-items: center;
        justify-content: flex-start;
    }
`

export const Background = styled.div`
    display: none;

    @media screen and (max-width: 700px){
        display: ${({menuMobile}) => menuMobile ? 'block' : 'none'};
        width: 100vw;
        height: 100vh;
        background-color: rgba(7, 8, 8, 0.5);
        position: fixed;
    }
`