import styled from 'styled-components';

export const Container = styled.div`

    input{
        padding: 5px;
        height: 38px;
        border-radius: 6px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;

    .divBranca{
        background-color: white;
        border-radius: 16px;
        width: 95%;
        height: 92vh;
    }

    .enviarPlanilha{
      display: none;
    }

    .button-container{
      justify-content: center;
    }

    .botaoRef{
      background-color: rgb(220, 68, 5);
      color: rgb(255, 255, 255);
      padding: 10px 10px;
      border-radius: 10px;
      border: none;
      margin-top: 20px;
      font-size: 16px;
    }

    .header{
        background-color: rgb(220, 68, 5);
        border-radius: 16px 16px 0 0;
        padding: 20px;
        color: white;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .close{
        border-radius: 100px;
        border: 2px solid white;
        padding: 0px 6px;
        font-size: 22px;
    }

    h6{
        display: none;
    }

    .width100{
        width: 100%;
        display: flex;
        margin-top: 10px;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;

        label{
            width: 45px !important;
            height: 45px !important;
        svg{
            width: 45px !important;
            height: 45px !important;
        }
        }

        p{
            font-size: 18px;
            font-weight: 600;
            max-width: 150px;
        }
    }

    @media(min-width: 700px){
        .divBranca {
            margin-left: 220px;
            width: 67%;
        }
    }

    @media(max-width: 600px){

        tr {
            display: table-row;
        } 

        td {
            text-align: center;
            display: table-cell;
        }

        thead{
          position: unset;
        }
        
    }
`

export const TableContainer = styled.div`
    overflow: auto;
    margin-top: 30px;
    padding-bottom: 30px;
    width: 90%;
    margin: 30px auto 0px;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: #dfdddd;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(220, 68, 5);
    border-radius: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;
    table-layout: unset;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }
    > tbody > tr > td {
      padding: 15px;
      font-size: 0.8rem;
    }

    td > span{
      display: block;
      color: red;
      font-weight: 900;
    }
  }

  .ativo {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 0.8rem;
      width: 60px;
    }
  }

  a {
    color: var(--azul);
    font-size: 0.8rem;
  }

  .validacao{
    width: 25px;
  }
`;