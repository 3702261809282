import styled from 'styled-components';

export const SaveButton = styled.button`
    background-color: #29C013;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    margin: auto;
`;

export const Container = styled.div`
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 60dvh;

    > .lista_precos {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 20px 10px;

        > .preco {
            display:flex;
            flex-direction:column;
            width:250px;
            margin-bottom:10px;

            > label.titulo {
                font-size: 20px;
                margin-right:5px;
                margin-bottom:5px;
                font-weight:bold ;
            }

            > label.legenda {
                font-size: 18px;
                margin-top: 5px;
            }

            > input {
                font-size: 20px;
                border-radius:9px;
                margin-right:5px;
                padding-left:5px;
            }
        }
    }
`;
